import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { fetchDirectly } from "../helpers";
import { getErrorMessage, validateData } from "../../environment/validation";
import TerraformConfigComponent from "./components/TerraformConfigComponent";

const TerraformConfig = ({ onNotification }) => {
  const { getAccessTokenSilently, user } = useAuth();
  const [vmSizeData, setVmSizeData] = useState([]);
  const [vmDiskData, setVmDiskData] = useState([]);
  const [vmRegionData, setVmRegionData] = useState([]);
  const [vmSubnetData, setVmSubnetData] = useState([]);
  const [vmResourceGroupData, setVmResourceGroupData] = useState([]);
  const [vmOperatingSystemData, setVmOperatingSystemData] = useState([]);
  const [environmentData, setEnvironmentData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [sandboxData, setSandboxData] = useState([]);

  const username = user?.name || user?.email?.split("@")[0] || "User";


  let [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const selectOption = {
    id: 0,
    title: "Please select an option...",
  };
  const zoneOptions = [
    selectOption,
    {
      id: 1,
      title: "Zone 1",
    },
    {
      id: 2,
      title: "Zone 2",
    },
    {
      id: 3,
      title: "Zone 3",
    },
  ];
  const routingOptions = [
    selectOption,
    {
      id: "InternetRouting",
      title: "Internet Routing",
    },
    {
      id: "MicrosoftRouting",
      title: "Microsoft Routing",
    },
  ];
  const title = "Terraform Config";
  const fields = [
    { headerName: "Do not use this panel unless you have been explicitly asked to do so.", type: "subtitle" },
    {
      headerName: "Resource Group",
      field: "terraform_vm_resource_group_id",
      type: "select",
      options: vmResourceGroupData,
      required: true,
    },
    { headerName: "Region", field: "terraform_vm_region_id", type: "select", options: vmRegionData, required: true },
    { headerName: "Zone", field: "terraform_vm_zone", type: "select", options: zoneOptions, required: true },
    { headerName: "Size", field: "terraform_vm_size_id", type: "select", options: vmSizeData, required: true },
    {
      headerName: "Operating System",
      field: "terraform_vm_operating_system_id",
      type: "select",
      options: vmOperatingSystemData,
      required: true,
    },
    { headerName: "Disk", field: "terraform_vm_disk_id", type: "select", options: vmDiskData, required: true },
    { headerName: "Routing", field: "terraform_vm_routing", type: "select", options: routingOptions, required: true },
    { headerName: "Below is the TOTAL MACHINE COUNT, if you input a number less than the current total, you will delete machines.", type: "subtitle" },
    { headerName: "Total Machine Count", field: "terraform_vm_count", type: "integer", required: true },
    { headerName: "A new subnet range must be selected when creating a new Resource Group. Select the current Resource Group Subnet otherwise.  Failure to do so will cause problems.  ", type: "subtitle" },
    { headerName: "Subnet Range", field: "terraform_vm_subnet_range", type: "select", options: vmSubnetData, required: true },
    { headerName: "Organization", field: "terraform_vm_org", type: "select", options: organizationData, required: true },
    { headerName: "Product GUID", field: "terraform_vm_product_guid",  type: "select", options: productData, required: true },
    { headerName: "Sandbox GUID", field: "terraform_vm_sandbox_guid",  type: "select", options: sandboxData, required: true },
    { headerName: "RH Deployment GUID", field: "terraform_vm_rhdeployment",  type: "select", options: environmentData, required: true },
    { headerName: "Site", field: "terraform_vm_site", type: "string", required: true },
    { headerName: "Fleet", field: "terraform_vm_fleet", type: "string", required: true },
  ];

  const MAX_RETRIES = 3;

  const fetchData = useCallback(async () => {
    let attempts = 0;
    let lastError;

    while (attempts < MAX_RETRIES) {
      try {
        //SIZE
        const sizeData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_size",
          method: "GET",
          token: await getAccessTokenSilently(),
        });

        setVmSizeData(
          sizeData.map((x) => ({
            id: x.virtual_machine_size_id,
            title: x.name,
          })),
        );
        setVmSizeData((vmSizeData) => [selectOption, ...vmSizeData]);

        //DISK
        const diskData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_disk",
          method: "GET",
          token: await getAccessTokenSilently(),
        });

        setVmDiskData(
          diskData.map((x) => ({
            id: x.virtual_machine_disk_id,
            title: `${x.name} - ${x.disk_type} ${x.disk_size}GB`,
          })),
        );
        setVmDiskData((vmDiskData) => [selectOption, ...vmDiskData]);

        //REGION
        const regionData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_region",
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmRegionData(
          regionData.map((x) => ({
            id: x.virtual_machine_region_id,
            title: x.name,
          })),
        );
        setVmRegionData((vmRegionData) => [selectOption, ...vmRegionData]);

        //RESOURCE GROUP
        const resourceGroupData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_resource_group",
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmResourceGroupData(
          resourceGroupData.map((x) => ({
            id: x.virtual_machine_resource_group_id,
            title: x.name,
          })),
        );
        setVmResourceGroupData((vmOperatingSystemData) => [selectOption, ...vmOperatingSystemData]);

        //OPERATING SYSTEM
        const operatingSystemData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_operating_system",
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmOperatingSystemData(
          operatingSystemData.map((x) => ({
            id: x.virtual_machine_operating_system_id,
            title: `${x.os_distribution} ${x.os_version}`,
          })),
        );
        setVmOperatingSystemData((vmOperatingSystemData) => [selectOption, ...vmOperatingSystemData]);

        const subnetData = await fetchDirectly({
          fullUrl: "terraform/virtual_machine_subnet",
          method: "GET",
          token: await getAccessTokenSilently(),
        });

        setVmSubnetData(
          subnetData.map((x) => ({
            id: x.subnet,
            title: `${x.name} ${x.subnet}`,
          })),
        );
        setVmSubnetData((vmSubnetData) => [selectOption, ...vmSubnetData]);

        //DASHBOARD DATA
        const dashboardData = await fetchDirectly({
          fullUrl: "admin/dashboard?page_size=100",
          method: "GET",
          token: await getAccessTokenSilently(),
        });


        setOrganizationData(
          dashboardData.data.map((x) => ({
            id: x.cust_org_id,
            title: `${x.customer_org}  - ${x.cust_org_id} `,
          })).filter((obj1, i, arr) =>
            arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
          )
        );
        setOrganizationData((organizationData) => [selectOption, ...organizationData]);

        setProductData(dashboardData.data.map((x) => ({
          id: x.cust_prod_id,
          title: `${x.prod_unique_name}  - ${x.customer_product} - ${x.cust_prod_id} `,
        })).filter((obj1, i, arr) =>
          arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
        ));
        setProductData((productData) => [selectOption, ...productData]);

        setSandboxData(
          dashboardData.data.map((x) => ({
            id: x.cust_sand_id,
            title: `${x.env_unique_name} - ${x.customer_sandbox} - ${x.cust_sand_id} `,
          })).filter((obj1, i, arr) =>
            arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
          )
        );
        setSandboxData((sandboxData) => [selectOption, ...sandboxData]);

        setEnvironmentData(
          dashboardData.data.map((x) => ({
            id: x.cust_env_id,
            title: `${x.env_unique_name} - ${x.customer_environment} - ${x.cust_env_id} `,
          })).filter((obj1, i, arr) =>
            arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
          )
        );
        setEnvironmentData((environmentData) => [selectOption, ...environmentData]);

        setData((data) => {
          return { ...data, terraform_user_name: username };
        })


        setIsLoading(false);
        setErrorMsg("");
        return; // Success, exit the function
      } catch (error) {
        lastError = error;
        attempts++;

        if (attempts < MAX_RETRIES) {
          onNotification(`Attempt ${attempts} failed. Retrying...`, "warning");
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Exponential backoff
        }
      }
    }
    // If we've reached this point, all retries have failed
    console.error("lastError", lastError);
    const errorMessage = getErrorMessage(lastError, fields);
    setErrorMsg(errorMessage);
    onNotification(`Failed to fetch data after ${MAX_RETRIES} attempts. Please try again later.`, "error");
    setIsLoading(false);
  }, [getAccessTokenSilently, onNotification, fields]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const handleSave = useCallback(async () => {
    try {
      validateData(fields, data)
      const response = await fetchDirectly({
        fullUrl: "terraform/run_terraform_job",
        method: "POST",
        body: data,
        token: await getAccessTokenSilently(),
      });
      setData(response);
      setErrorMsg("");
      onNotification("Terraform Job sent successfully", "success");
    } catch (error) {
      const errorMessage = getErrorMessage(error, fields);
      setErrorMsg(errorMessage);
      onNotification(errorMessage, "error");
    }
  }, [data, getAccessTokenSilently, fields, onNotification]);

  return (
    <div className="w-full">
      <TerraformConfigComponent
        title={title}
        fields={fields}
        data={data}
        onDataChange={handleDataChange}
        isLoading={isLoading}
        onSave={handleSave}
        errorMsg={errorMsg}
      />
    </div>
  );
};

export default TerraformConfig;
