import React, { useState, useEffect, useMemo, useReducer, useContext } from "react";
import { createPortal } from "react-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "contexts/LayoutContext";
import AccountContext from "contexts/AccountContext";

import { formReducer, initialState, initialMutationsState, mutationsReducer } from "./formReducer";

import { getErrorMessage, usePortalQuery, usePortalMutation, validateData, apiRequest } from "common/apiUtils";

import FormField from "components/common/FormField";
// import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import Modal from "components/common/Modal";
// import SkeletonLoader from "components/common/SkeletonLoader";
import { ChevronIcon } from "assets/svgs";
import definitions from "common/definitions.json";

function getTabs(config) {
  const editor = config?.editor;
  if (!editor) return [];

  let tabs = Object.keys(editor).filter((key) => key !== "group");

  if (editor.group) {
    tabs = tabs.concat(Object.keys(editor.group?.tabs));
  }
  return tabs;
}

function getEditorConfig(config, editorTab) {
  return config?.editor[editorTab] ? config?.editor[editorTab] : undefined;
}

export default function Form({
  item,
  config,
  tab,
  setSelectedRow,
  setRowSelectionModel,
  setDrawerLoadingMessage,
  newModel,
  buttonsMap,
  readPermitted,
  editPermitted,
  additionalButtons,
  actionsOnCreate,
  topBanner,
}) {
  const { getAccessToken } = useAuth();
  const { currentStateData } = useContext(AccountContext);
  const { tableSize } = useLayout();
  const queryClient = useQueryClient();

  const tabs = getTabs(config);
  const defaultTab = useMemo(() => {
    // Ensure config and config.editor are defined
    const editorConfig = config?.editor || {};

    // Use default tab if set, otherwise, use the first tab
    return (
      newModel?.tab ??
      Object.keys(editorConfig).find((key) => editorConfig[key]?.default) ??
      Object.keys(editorConfig)[0]
    );
  }, [config, newModel]);

  const [editorTab, setEditorTab] = useState(defaultTab);

  const editorConfig = getEditorConfig(config, editorTab);
  const stateKey = useMemo(() => editorConfig?.schemas?.GET, [editorConfig]);

  const [state, dispatch] = useReducer(formReducer, initialState);
  const [mutationsRegistry, dispatchMutationsRegistry] = useReducer(mutationsReducer, initialMutationsState);

  const [error, setError] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [allRequired, setAllRequired] = useState(false);
  const [mutationStates, setMutationStates] = useState({});
  const [prevItem, setPrevItem] = useState();
  const [showModal, setShowModal] = useState(false);

  const [summary, setSummary] = useState();
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const targetTypeSchemaMap = {
    loot: { schema: "LootCollection", pluralRowId: "loot_ids", rowId: "loot_id" },
    price_point: { schema: "PricePointCollection", pluralRowId: "price_point_ids", rowId: "price_point_id" },
    kv: { schema: "AllKvs", pluralRowId: "keys", rowId: "key" },
  };

  const scheduleModsQuery = usePortalQuery({
    schema: "ScheduleModificationListResponse",
    token: getAccessToken(),
    replacements: {
      schedule_id: item?.original?.schedule_id ?? item?.schedule_id,
      product_id: currentStateData?.product?.productId,
    },
    newModel,
  });

  async function generateSummary(sandboxId) {
    // This reducer function parses through the mods to find the target type and all of the fk values that are listed in the targetTypeSchemaMap and create a hashmap of them
    let parsedMods = scheduleModsQuery?.data?.data?.reduce((acc, mod) => {
      acc[mod.target_type] = {
        ...acc[mod.target_type],
        [mod.target_id]: {
          ...acc[mod.target_type]?.[mod.target_id],
          [mod.field_name]: mod.value_string ?? mod.value_number ?? mod.value_boolean ?? mod.value_array ?? "",
        },
      };
      return acc;
    }, {});

    // This loop goes through the parsedMods and makes api calls to get the fk values. This includes the base target_type and all of the fk values that are listed in the targetTypeSchemaMap
    const promises = [];
    for (const modType in parsedMods) {
      const modIds = new Set(Object.keys(parsedMods[modType]));
      const queryString = Array.from(modIds)
        .map((id) => `${targetTypeSchemaMap[modType].pluralRowId}=${id}`)
        .join("&");
      const endpoint = definitions?.schemas[targetTypeSchemaMap[modType].schema]?.endpoints.find(
        (e) => e.method === "get",
      );

      promises.push(
        apiRequest(getAccessToken(), endpoint, { sandbox_id: sandboxId }, null, null, {
          queryParams: queryString,
        }),
      );
    }

    // This groups the results by the target_type
    const groupedResults = await Promise.all(promises).then((results) => {
      // Initialize an object using the schema types from targetTypeSchemaMap
      const groupedResults = Object.keys(targetTypeSchemaMap).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});

      results.forEach((result) => {
        if (!result?.data?.length) return;

        // Find the type by checking which rowId matches
        const type = Object.entries(targetTypeSchemaMap).find(([_, schema]) =>
          result.data[0]?.hasOwnProperty(schema.rowId),
        )?.[0];

        if (type) {
          groupedResults[type].push(...result.data);
        }
      });

      return groupedResults;
    });

    // This loop uses the fetched results to replace or augment the mod summary with plain english names rather than ids
    let namedMods = {};
    for (const type in parsedMods) {
      for (const id in parsedMods[type]) {
        const name = groupedResults[type].find((r) => {
          return r?.[targetTypeSchemaMap[type].rowId] === id;
        })?.name;

        if (!namedMods[type]) {
          namedMods[type] = {};
        }

        const modData = { ...parsedMods[type][id] };
        // This finds a similar matching key like "current_price_point_id" that contains "price_point_id" and then replaces the value with the name of the item from the grouped results
        Object.entries(modData).forEach(([field, value]) => {
          Object.entries(targetTypeSchemaMap).forEach(([targetType, schema]) => {
            if (field.includes(schema.rowId)) {
              const matchingItem = groupedResults[targetType]?.find((item) => item[schema.rowId] === value);
              if (matchingItem?.name) {
                modData[field] = `${matchingItem.name} (${value})`;
              }
            }
          });
        });

        if (name) {
          namedMods[type][`${name} (${id})`] = modData;
        } else {
          namedMods[type][`${id}`] = modData;
        }
      }
    }
    setSummary(namedMods);
  }

  // Create replacements object for the API call with _id and _identifier
  const replacements = useMemo(() => {
    return Object.entries(item).reduce(
      (acc, [key, value]) => {
        const idRegex = /id$/;
        if (idRegex.test(key)) {
          acc[key] = value;
          acc[key.replace("id", "identifier")] = value;
        }
        return acc;
      },
      {
        product_id: currentStateData?.product?.productId,
        environment_id: currentStateData?.environment?.environmentId,
      },
    );
  }, [item?.[config?.rowId], currentStateData?.product?.productId, item, currentStateData?.environment?.environmentId]);

  const formDataQuery = usePortalQuery({
    schema: editorConfig?.schemas?.GET,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    newModel: !!newModel,
  });

  const updateMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.MUTATE,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    method: "put",
    onSuccessCallback: () => {
      setIsDirty(false);
      setAllRequired(false);

      // Delay the success message to allow the Save/Cancel buttons to animate off
      // and Success to animate on
      const timeout1 = setTimeout(() => {
        setShowSuccessMessage(true);
        clearTimeout(timeout1);
      }, 200);
      const timeout2 = setTimeout(() => {
        setShowSuccessMessage(false);
        clearTimeout(timeout2);
      }, 2500);
    },
    onErrorCallback: () => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
    },
  });

  const deleteMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.DELETE,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    method: "delete",
    onMutateCallback: () => {
      setDrawerLoadingMessage("Deleting the account, please wait...");
    },
    onErrorCallback: () => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
    },
    onSuccessCallback: (data) => {
      setSelectedRow && setSelectedRow();
      setRowSelectionModel({});
      setDrawerLoadingMessage();
    },
  });

  const createMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.POST,
    token: getAccessToken(),
    replacements: { ...replacements, schedule_id: item?.schedule_id ?? item?.original?.schedule_id },
    configs: editorConfig,
    method: "post",
    onErrorCallback: (error) => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
      setDrawerLoadingMessage();
    },
    onMutateCallback: () => {
      setDrawerLoadingMessage("Creating, please wait...");
    },
    onSuccessCallback: async (data) => {
      setSelectedRow && setSelectedRow({ original: data });
      setRowSelectionModel({ [data[config?.rowId]]: true });
      setDrawerLoadingMessage();
    },
  });

  function toggleModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal((prev) => !prev);
  }

  function handleCancel() {
    setError();
    newModel ? handleChange(newModel?.fields) : handleChange(formDataQuery.data);
    setIsDirty(false);
  }

  function handleSave() {
    const errors = validateData(
      state?.[stateKey],
      newModel ? editorConfig?.fields : editedFieldsToValidate,
      config.rowId,
    );
    if (errors.length > 0) {
      setError(errors.join(", "));
      return;
    }

    setError(null);

    const mutationKeys = Object.keys(mutationsRegistry).reduce((acc, key) => {
      if (state?.[key]) acc.push(key);
      return acc;
    }, []);

    setMutationStates(mutationKeys.reduce((acc, key) => ({ ...acc, [key]: "pending" }), {}));
    // All of the variables that are saved in the mutationsRegistry are the ones that are being used
    // in the mutations. Because this is very async and pointing to different parts of the api,
    // .mutateAsync is used to let us make manual calls rather than relying on react-query's
    // normal helpful methods.

    const mutationPromises = mutationKeys.map((key) => {
      let data = state?.[key];
      // If the data is not an array, we need to add the assigned_org_id
      // In this case, only GroupPermissionsEditor sends up an array.
      if (!Array.isArray(data)) {
        data = {
          ...state?.[key],
          assigned_org_id: currentStateData?.org?.orgId,
        };
      }
      const { schema, editorConfig, replacements } = mutationsRegistry?.[key];

      if (key && data) {
        setDrawerLoadingMessage("Saving, please wait...");

        return updateMutation
          .mutateAsync({
            data,
            schemaOverride: schema,
            configsOverride: editorConfig,
            replacementsOverride: replacements,
          })
          .then((result) => {
            dispatch({
              type: "UPDATE_STATE",
              payload: { stateKey: schema, value: result },
            });

            setMutationStates((prev) => ({ ...prev, [key]: "success" }));

            queryClient.refetchQueries({
              queryKey: [schema, replacements],
            });

            return { editorTab, status: "success" };
          })
          .catch((error) => {
            setMutationStates((prev) => ({ ...prev, [key]: "error" }));
            throw error; // Rethrow to be caught in the main catch block
          });
      }
      return Promise.resolve();
    });

    Promise.all(mutationPromises)
      .then((results) => {
        return results;
      })
      .catch((error) => {
        console.log(error);
        const errorMsg = getErrorMessage(error, editorConfig?.editor);
        setError(errorMsg);
      })
      .finally(() => {
        const isAnyPending = Object.values(mutationStates).some((state) => state === "pending");

        if (!isAnyPending) {
          setError();
          setIsDirty(false);
          setAllRequired(false);
          setDrawerLoadingMessage();
          queryClient.refetchQueries({ queryKey: [tab, currentStateData?.environment?.environmentId] });
          queryClient.refetchQueries({ queryKey: [editorConfig?.schemas?.GET] });
        }
      });
  }

  async function handleDelete() {
    setShowModal(false);
    try {
      await deleteMutation.mutateAsync();
    } catch (error) {
      console.log(error);
      setError(error);
    }
    try {
      // One time to delete to remove the detached schedule from db
      apiRequest(
        getAccessToken(),
        { path: definitions?.schemas?.ScheduleBase?.endpoints[0]?.path, method: "delete" },
        replacements,
      );
      queryClient.refetchQueries({ queryKey: [tab] });
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  async function handlePost() {
    const errors = validateData(state?.[stateKey], editorConfig?.fields, config.rowId);
    if (errors.length > 0) {
      setError(errors.join(", "));
    } else {
      setError();
      const data = { ...state?.[stateKey] };
      delete data?.[config?.rowId];
      let response = await createMutation.mutateAsync({
        data,
      });

      // One off to attach this schedule to this environment
      await apiRequest(
        getAccessToken(),
        definitions?.schemas?.EnvironmentScheduleEntryCreate?.endpoints[0],
        replacements,
        { schedule_id: response?.schedule_id },
      );
    }
    queryClient.refetchQueries({ queryKey: [tab, replacements] });

    if (actionsOnCreate) {
      actionsOnCreate.forEach((action) => {
        action.fn(...action.args);
      });
    }
  }

  const editedFieldsToValidate = useMemo(() => {
    const editor = config?.editor;
    if (!editor) return [];

    let fields = [];

    if (editor[editorTab]?.fields) {
      fields = fields.concat(editor[editorTab].fields);
    }

    return fields.filter((field) => field?.editable !== "hideAfterCreate");
  }, [editorTab, config]);

  function handleChange(value) {
    dispatch({
      type: "UPDATE_STATE",
      payload: { stateKey, value },
    });
  }

  function setDataWithDirtyCheck(newData) {
    handleChange(newData);

    dispatchMutationsRegistry({
      type: "UPDATE_MUTATIONS_REGISTRY",
      payload: {
        stateKey,
        value: {
          schema: editorConfig?.schemas?.MUTATE,
          editorConfig,
          replacements,
        },
      },
    });

    if (!isDirty) {
      setIsDirty(true);
    }
  }

  function filterOmitForNewModel(field) {
    return field?.type === "metadata" && field?.editable !== "lockAfterCreate" && newModel?.fields ? false : true;
  }

  function filterOmitNotNewModel(field) {
    if (field?.editable === "hideAfterCreate" && !newModel) return false;
    return true;
  }

  useEffect(() => {
    //I added newModel as a dependency because i want to make sure that the reducer is set correctly when a person Clicks "Add" and then clicks to another row
    if (formDataQuery.data && prevItem?.[config?.rowId] !== item?.[config?.rowId]) {
      dispatch({
        type: "INIT_STATE",
        payload: { stateKey, value: formDataQuery.data },
      });
      dispatchMutationsRegistry({
        type: "RESET_DATA",
      });
      setPrevItem(item);
    } else if (formDataQuery.data && !state?.[stateKey]) {
      // This happens when a tab is switched and the data is not yet loaded
      dispatch({
        type: "UPDATE_STATE",
        payload: { stateKey, value: formDataQuery.data },
      });
    }
  }, [formDataQuery.data, newModel]);

  useEffect(() => {
    if (!state?.[stateKey]) return;
    const fields = newModel ? editorConfig?.fields : editedFieldsToValidate;
    const errors = validateData(state?.[stateKey], fields, config.rowId);

    if (errors.length === 0) setAllRequired(true);
    else setAllRequired(false);
  }, [editorTab, state?.[stateKey]]);

  useEffect(() => {
    if (newModel) {
      setEditorTab(newModel?.tab);
      dispatch({
        type: "INIT_STATE",
        payload: {
          stateKey: config?.editor?.[newModel?.tab].schemas.GET,
          value: newModel?.fields,
        },
      });
      setPrevItem(item);
      setIsDirty(false);
    }
  }, [newModel]);

  useEffect(() => {
    setIsDirty(false);
    if (!item) {
      setWidthFn(0);
    }
    if (prevItem?.[config?.rowId] !== item?.[config?.rowId]) {
      setError();
      setEditorTab(defaultTab);
    }
  }, [item]);

  useEffect(() => {
    if (item?.original?.sandbox_id) {
      syncModdedLootData(item?.original?.sandbox_id);
    }
  }, [item?.original?.sandbox_id]);

  useEffect(() => {
    if (currentStateData?.environment?.connectedSandboxId && scheduleModsQuery.data) {
      generateSummary(currentStateData?.environment?.connectedSandboxId);
    }
  }, [scheduleModsQuery.data]);

  return (
    <div className="h-full pl-1">
      {tabs.length > 0 && (
        <MenuBar
          className="sticky top-0"
          buttons={buttonsMap}
          tabs={newModel ? [] : tabs}
          setActiveTab={setEditorTab}
          activeTab={editorTab}
        />
      )}
      {topBanner ? (
        topBanner
      ) : (
        <div className="flex items-center justify-end bg-[#121212] w-full py-2 px-2 border-l border-r border-b border-zinc-800 relative overflow-hidden">
          <div
            className={`flex gap-2 transition-all duration-300 relative z-20 ${
              isDirty && allRequired && editPermitted ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
            }`}
          >
            <button
              onClick={handleCancel}
              className={`bg-zinc-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-zinc-700 w-20 ${tableSize}`}
            >
              Cancel
            </button>
            <button
              onClick={newModel ? handlePost : handleSave}
              className={`bg-violet-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-violet-700 w-20 ${tableSize}`}
            >
              {updateMutation.isPending ? "Saving..." : "Save"}
            </button>
          </div>

          {showSuccessMessage && (
            <div className="absolute top-1/2 right-3 transform -translate-y-1/2 animation-fade-in-out">
              <div className="p-1 text-xs rounded-sm font-medium text-green-500 border border-green-500/20 bg-green-900/20">
                Success
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`text-red-500 grid transition-all px-3 ${
          formDataQuery.isError || !!error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        }`}
      >
        <p
          className={`overflow-hidden font-lato border-red-500/20 rounded-sm ${
            formDataQuery.isError || !!error ? "p-2 border-2 mt-3" : "p-0 border-0"
          } box-border`}
        >
          {formDataQuery.isError ? getErrorMessage(formDataQuery.error, config?.[editorTab]?.editor) : ""}
          {error && error.split("\n").map((error) => <React.Fragment key={error}>{error}</React.Fragment>)}
        </p>
      </div>
      <form className="relative p-2 min-h-[460px]">
        {additionalButtons && (item?.[config?.rowId] || item?.original?.[config?.rowId]) && (
          <div className="flex gap-2 mt-1 mb-2 justify-end">
            {additionalButtons.map((button) => (
              <div
                key={button.name + item?.[config?.rowId] || item?.original?.[config?.rowId]}
                className={`text-white font-medium rounded-sm text-center inline-flex items-center z-10 px-3 py-1 gap-2 transition-colors ease-in-out bg-violet-800 hover:bg-violet-900 cursor-pointer ${tableSize}`}
                onClick={() => button.fn(button.args)}
              >
                {button.name}
              </div>
            ))}
          </div>
        )}
        {editorTab && (
          <div className={`${updateMutation.isPending ? "pointer-events-none" : ""}`}>
            {(formDataQuery?.isSuccess || newModel) && state?.[stateKey] && (
              <div>
                {editorConfig?.fields
                  .filter(filterOmitForNewModel)
                  .filter(filterOmitNotNewModel)
                  .map((field) => (
                    <div key={Object.entries(field).join()}>
                      <FormField
                        field={{
                          ...field,
                          type:
                            field?.editable === "hideAfterCreate" && newModel
                              ? field?.type || "text"
                              : field?.editable === "lockAfterCreate" && !newModel
                                ? "metadata"
                                : field.type || "text",
                        }}
                        rowKey={field.field}
                        fieldGroup={state?.[stateKey]}
                        onChange={setDataWithDirtyCheck}
                        originalValue={item}
                        description={
                          definitions?.schemas?.[editorConfig?.schemas?.GET ?? config.schema]?.[field.field]
                            ?.description
                        }
                        disabled={!editPermitted}
                      />
                    </div>
                  ))}
                {editorTab === "Info" && tab === "Events" && !newModel && summary && (
                  <div className="text-white/70 pb-10">
                    <p
                      className="text-base font-medium mb-1 inline-flex items-center gap-1 cursor-pointer"
                      onClick={() => setSummaryExpanded((prev) => !prev)}
                    >
                      Modifications Summary
                      <span className={`transition-all ${summaryExpanded ? "" : "rotate-180"}`}>
                        <ChevronIcon />
                      </span>
                    </p>
                    {summary && (
                      <div
                        className={`rounded border px-1 grid transition-all ${
                          summaryExpanded
                            ? "grid-rows-[1fr] py-1 border border-white/10"
                            : "grid-rows-[0fr] py-0 border-transparent"
                        }`}
                      >
                        <div className="overflow-hidden">
                          {Object.entries(summary).map(([type, mods]) => (
                            <div key={type}>
                              <div className="items-center gap-2 capitalize pr-2 mb-1 flex pl-2">
                                <p className="text-sm font-medium pb-1">{type}:</p>
                                <p className="text-sm font-medium pb-1">{Object.keys(mods).length}</p>
                              </div>
                              <div className="flex flex-col px-2">
                                {Object.entries(mods).map(([name, mod]) => (
                                  <div className="mb-2 p-2 odd:bg-white/5 rounded" key={name}>
                                    <p className="text-xs font-medium">{name}</p>
                                    {Object.entries(mod).map(([key, value]) => (
                                      <p className="text-xs pl-2" key={key}>
                                        {`${key}:`}
                                        <span className="text-xs font-light block pl-2">
                                          {value.toString() || "no value"}
                                        </span>
                                      </p>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {editorTab === "Info" &&
                  !newModel &&
                  !updateMutation.isPending &&
                  !formDataQuery.isFetching &&
                  !formDataQuery.isError &&
                  editPermitted && (
                    <div className="text-right">
                      <button
                        onClick={toggleModal}
                        className={`text-white mt-6 mb-12 font-medium rounded-sm text-center inline-flex items-center z-10 px-3 py-1 gap-2 transition-colors duration-200 ease-in-out bg-rose-800 hover:bg-pink-950 ${tableSize}`}
                        title="Delete"
                      >
                        Delete
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </form>
      {showModal &&
        createPortal(
          <Modal
            isOpen={showModal}
            onClose={toggleModal}
            onConfirm={handleDelete}
            title={"Are you sure?"}
            message={`You are about to delete ${item?.org_group_name ?? item?.account_email ?? item?.name ?? item?.description}. ${
              item?.group_id ? `This will impact all members of this group.` : ""
            }`}
          />,
          document.body,
        )}
    </div>
  );
}
