import { useState, useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { ChevronIcon } from "assets/svgs";
import { motion } from "framer-motion";

export default function SubMenu({
  linkPrefix,
  name,
  segmentName,
  selected,
  showSelected,
  data,
  icon,
  isAdmin,
  alwaysExpanded,
}) {
  const [expanded, setExpanded] = useState(true);
  const [dropdownHeight, setDropdownHeight] = useState();
  const [showChevron, setShowChevron] = useState(false);
  const [token, setToken] = useState();
  const { user, getAccessTokenSilently } = useAuth();

  const location = useLocation();

  function isLeaf(shortName) {
    const pathSegments = location.pathname.split("/");
    pathSegments.shift();

    if (!isAdmin) {
      if (pathSegments.includes(segmentName) && pathSegments.includes(shortName)) {
        if (pathSegments.at(-1) === segmentName || pathSegments.at(-2) === segmentName) {
          return "leaf";
        } else return "parent";
      }
      return false;
    } else return false;
  }
  function resizeDropdown() {
    const el = document.getElementById(`${segmentName}-menu`);
    if (expanded) {
      el.style.height = "";
    }
    setDropdownHeight(el.scrollHeight + "px");
  }

  function toggleDropdown() {
    if (!alwaysExpanded) {
      if (!expanded) {
        resizeDropdown();
      } else if (showSelected) {
        setDropdownHeight("40px");
      } else setDropdownHeight("0px");
    }

    if (!alwaysExpanded) setExpanded((prev) => !prev);
  }

  function moveSelectedFirst(a, b) {
    if (!showSelected) return 0;
    else if (location.pathname.split("/").includes(a.shortName)) return -1;
    else return 1;
  }

  async function getToken() {
    const token = await getAccessTokenSilently();
    setToken(token);
  }

  useEffect(() => {
    getToken();
    if (data.filter((x) => !x.archive).length > 1 && !alwaysExpanded) {
      setShowChevron(true);
    } else setShowChevron(false);

    if (!isAdmin) {
      const inActiveRoute = data.find((x) => location.pathname.split("/").includes(x.shortName) && !x.archive);
      // Collapses if this is active and child isn't selected, expands if this is not yet selected, but visible
      if (!inActiveRoute && expanded) {
        resizeDropdown();
      } else if ((inActiveRoute && expanded) || (!inActiveRoute && !expanded)) {
        toggleDropdown();
      }
    }
  }, [location.pathname, data]);

  return (
    <div className="relative">
      <div
        className="flex items-center w-full box-border mt-2 px-6 py-1 opacity-40 text-white cursor-pointer"
        onClick={toggleDropdown}
      >
        {icon && icon}
        <h3 className="font-bold text-sm uppercase">{name}</h3>
        {showChevron && (
          <motion.div animate={{ rotate: expanded ? 0 : 180 }} className={`inline ml-auto`}>
            <ChevronIcon fill="white" width="20" />
          </motion.div>
        )}
      </div>

      {data && (
        <div
          id={`${segmentName}-menu`}
          className={`overflow-hidden transition-[height]`}
          style={{ height: dropdownHeight }}
        >
          {data
            .filter((x) => !x.archive)
            .sort(moveSelectedFirst)
            .map((elem) => {
              if (elem.externalLink) {
                const commonProps = {
                  className: "menu-link",
                  key: elem[`${segmentName}Id`],
                  target: "_blank",
                  href: elem.grafana 
                    ? `${elem.externalLink}?${elem?.tokenQueryParam ?? "authToken"}=${token}&orgId=${elem.grafanaOrgId}`
                    : `${elem.externalLink}?${elem?.tokenQueryParam ?? "authToken"}=${token}&user=${user?.email}`
                };

                const spanClassName = `px-2 py-1 transition-colors w-full rounded box-border border-2 ${
                  isAdmin && location.pathname.split("/").includes(elem.shortName)
                    ? "text-white bg-selectedPurple border-transparent"
                    : !selected
                      ? "border-transparent hover:bg-selectedPurple" 
                      : isLeaf(elem.shortName) === "parent"
                        ? "text-white border-selectedPurple hover:bg-selectedPurple"
                        : isLeaf(elem.shortName) === "leaf"
                          ? "text-white bg-selectedPurple border-transparent"
                          : "hover:bg-white/10 border-transparent"
                }`;

                return (
                  <a className="menu-link" 
                     key={elem[`${segmentName}Id`]} 
                     target="_blank"
                     href={elem.grafana 
                       ? `${elem.externalLink}?${elem?.tokenQueryParam ?? "authToken"}=${token}&orgId=${elem.grafanaOrgId}`
                       : `${elem.externalLink}?${elem?.tokenQueryParam ?? "authToken"}=${token}&user=${user?.email}`}>
                    <span className={spanClassName}>
                      {elem.icon}
                      <span>{elem[`${segmentName}Name`]}</span>
                    </span>
                  </a>
                );
              } else {
                return (
                  <Link to={`${linkPrefix}/${elem.shortName}`} className="menu-link" key={elem[`${segmentName}Id`]}>
                    <span
                      className={`px-2 py-1 transition-colors w-full rounded box-border border-2 ${
                        isAdmin && location.pathname.split("/").includes(elem.shortName)
                          ? "text-white bg-selectedPurple border-transparent"
                          : !selected
                            ? "border-transparent hover:bg-selectedPurple"
                            : isLeaf(elem.shortName) === "parent"
                              ? "text-white border-selectedPurple hover:bg-selectedPurple"
                              : isLeaf(elem.shortName) === "leaf"
                                ? "text-white bg-selectedPurple border-transparent"
                                : "hover:bg-white/10 border-transparent"
                      }`}
                    >
                      <span>{elem[`${segmentName}Name`]}</span>
                    </span>
                  </Link>
                );
              }
            })}
        </div>
      )}
      <div className="h-[1px] w-ful mt-2">
        <div className="h-full mx-4 bg-white/10" />
      </div>
    </div>
  );
}
