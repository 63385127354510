import { useRef, useEffect, cloneElement, Children } from "react";
import { TableSizeIcon, OpenDrawerIcon, RowMenuIcon } from "assets/svgs";
import { useLayout } from "contexts/LayoutContext";
export default function RightDrawer({ setWidthFn = () => {}, width, defaultWidth = 300, isResizing, children }) {
  const { setLayout } = useLayout();
  const drawerRef = useRef(null);
  useEffect(() => {
    const handleMouseDown = (event) => {
      event.preventDefault();
      isResizing.current = true;
      const startX = event.clientX;
      const startWidth = drawerRef.current.offsetWidth;
      let newWidth;
      const handleMouseMove = (moveEvent) => {
        const currentX = moveEvent.clientX;
        const delta = startX - currentX;
        newWidth = Math.max(defaultWidth, startWidth + delta); // Set minimum width to 200px
        const maxWidth = window.innerWidth - 425; // Maximum width is screen width minus 264px
        newWidth = Math.min(newWidth, maxWidth); // Ensure newWidth does not exceed maxWidth

        setWidthFn && setWidthFn(newWidth);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        setLayout("global", "defaultDrawerWidth", newWidth);
        isResizing.current = false;
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const resizer = drawerRef.current.querySelector(".resizer");
    resizer.addEventListener("mousedown", handleMouseDown);

    return () => {
      resizer.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <div
      ref={drawerRef}
      className="bg-zinc-900 text-white flex flex-col flex-grow overflow-hidden relative"
      style={{
        width: `${width}px`,
        transform: width > 0 ? "translateX(0)" : `translateX(${width}px)`,
        transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
      }}
    >
      <div className="resizer w-1 h-full cursor-col-resize absolute left-0 top-0 z-50 bg-gradient-to-l from-zinc-600 to-zinc-700" />
      <div className=" flex-grow overflow-y-auto">
        {Children.map(children, (child) =>
          cloneElement(child, {
            width,
          }),
        )}
      </div>
    </div>
  );
}
