export default {
  pageKey: "scheduler",
  tabs: {
    Events: {
      rowId: "schedule_id",
      pluralRowId: "schedule_ids",
      targetType: "schedule",
      default: true,
      schema: "EnvironmentScheduleEntryResponse",
      editor: {
        Info: {
          schemas: {
            MUTATE: "ScheduleBase",
            GET: "ScheduleResponse",
            POST: "ScheduleCreate",
            DELETE: "EnvironmentScheduleEntryBase",
          },
          fields: [
            {
              field: "schedule_id",
              headerName: "Schedule ID",
              type: "metadata",
              tableVisibility: false,
            },
            {
              field: "name",
              headerName: "Name",
              required: true,
            },
            {
              field: "description",
              headerName: "Description",
            },
            {
              field: "start_datetime",
              headerName: "Event Start",
              type: "datetime",
              required: true,
            },
          ],
        },
      },
    },
  },
};
