import { useContext, useState, useEffect, useRef } from "react";
import { useEnvironmentStyles } from "./styles";
import { fetchDirectly } from "common/apiUtils";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import RHDataOnlyField from "components/common/RHDataOnlyField";
import RHEnhancedAutocompleteField from "components/common/RHEnhancedAutocompleteField";
import { useHistory } from "react-router-dom";
import { Alert, Box, Button, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import GoToSandboxIcon from "@mui/icons-material/ExitToApp";
import { getErrorMessage, validateData } from "./validation";
import RHFieldWrapper from "../../components/common/RHFieldWrapper";
import DateTimePicker from "../../components/common/DateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const DATE_FORMAT = "MM/DD/YYYY hh:mm:ss A";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function EnvironmentEdit(props) {
  const { onSave, isDialog, onDialogClose, inputEnvironment } = props;
  const { getAccessTokenSilently } = useAuth();
  const classes = useEnvironmentStyles();
  const history = useHistory();
  const { currentStateData, permissionData, refreshAccount, hasPermission } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [environment, setEnvironment] = useState({});
  const initialEnvironment = useRef();
  const sandboxOptions = useRef();
  const isAddNew = useRef(props.isAddNew);
  const [resultMsg, setResultMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  if (!sandboxOptions.current) {
    sandboxOptions.current = currentStateData?.product?.sandboxes
      .filter((x) => !x.archive && !x.readonly)
      .map((x) => ({ id: x.sandboxId, title: x.sandboxName }));
  }

  const columns = [
    { headerName: "Name", field: "name", required: true },
    { headerName: "Short Name", field: "short_name", required: true },
    { headerName: "Base URL", field: "base_url", required: true },
    {
      headerName: "Primary Contact Email",
      field: "primary_contact_email",
      required: true,
      style: { paddingBottom: "0", marginBottom: "20px" },
    },
    {
      headerName: "Sandbox",
      field: "sandbox_id",
      required: true,
      fullUrl: true,
      type: "enhancedautocomplete",
      defaultOptions: sandboxOptions.current,
    },
    {
      headerName: "Authentication State",
      field: "authentication_state",
      required: true,
      type: "enhancedautocomplete",
      defaultOptions: [
        { id: "open", title: "Open" },
        { id: "limited", title: "Limited" },
        { id: "locked", title: "Locked" },
      ],
    },
    {
      headerName: "Kick Before Hint",
      field: "kick_before_hint",
      required: false,
      type: "date",
    },
  ];

  async function fetchData(inputEnvironment) {
    if (!inputEnvironment) {
      inputEnvironment = currentStateData?.environment;
    }
    setErrorMsg();
    if (inputEnvironment?.environmentId) {
      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${
          currentStateData?.environment?.environmentId ?? inputEnvironment?.environmentId
        }`;
        const response = await fetchDirectly({
          fullUrl: endpoint,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        initialEnvironment.current = response;
        setEnvironment(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setResultMsg(getErrorMessage(error, columns));
        setIsLoading(false);
      }
    }
  }

  const handleGoToSandbox = () => {
    setErrorMsg();
    const sandbox = currentStateData.product?.sandboxes.find((sandbox) => sandbox.sandboxId === environment.sandbox_id);

    if (!sandbox) {
      setErrorMsg("Sandbox not found");
      return;
    }
    history.push(
      `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/sandbox/${sandbox.shortName}`
    );
  };

  function isPermitted() {
    const env_id = initialEnvironment.current?.environment_id;
    return hasPermission("environment:config:edit", "environment", env_id, currentStateData, permissionData);
  }

  function isSandboxViewPermitted() {
    if (permissionData?.isGlobalAdmin || currentStateData.product.sandboxes.length > 0) {
      return true;
    }
    return false;
  }

  const handleEdit = () => {
    setIsDirty(true); // remove to disable buttons until data changed
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setErrorMsg();
    if (!isAddNew.current) {
      setIsEditMode(false);
    }
    setIsDirty(false);
    setEnvironment(initialEnvironment.current);
    handleResultClose();
  };

  const handleChange = (event) => {
    let { name, value, type } = event.target;

    if (type === "date") {
      // If the value is an empty string or null, set it to null
      value = value === "" || value === null ? null : value;
    } else {
      value = value?.trim?.().length === 0 ? null : value;
    }

    setEnvironment((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsDirty(true);
  };

  const handleOnOffChange = (event) => {
    // leave here, coming soon...
    //const checked = event.target.checked;
  };

  const save = async (envToSave) => {
    console.log("Sending to API:", environment);
    setErrorMsg();
    validateData(columns, environment);

    // Create a new object with base_sandbox_id instead of sandbox_id
    const modifiedEnv = {
      ...envToSave,
      base_sandbox_id: envToSave.sandbox_id
    };
    delete modifiedEnv.sandbox_id;

    let endpoint = `v1/product/${currentStateData?.product?.productId}/environment`;
    if (!isAddNew.current) {
      endpoint = `${endpoint}/${environment.environment_id}`;
    }

    const method = isAddNew.current ? "POST" : "PUT";
    const response = await fetchDirectly({
      fullUrl: endpoint,
      method: method,
      body: modifiedEnv,  // Send the modified object
      token: await getAccessTokenSilently(),
    });
    await refreshAccount();
    return response;
  };

  const handleSaveAndClose = async (envToSave) => {
    try {
      // Validate the kick_before_hint date
      if (envToSave?.kick_before_hint) {
        const parsedDate = dayjs(envToSave.kick_before_hint);
        if (!parsedDate.isValid()) {
          throw new Error("Invalid date format for kick_before_hint");
        }
        const now = dayjs();
        if (parsedDate.isAfter(now)) {
          throw new Error("kick_before_hint date cannot be in the future");
        }
      }

      const response = await save(envToSave);

      setEnvironment(response);
      setIsDirty(false);
      setIsEditMode(false);
      setErrorMsg(null);

      if (isDialog) {
        onSave("environment", environment?.name);
        handleResultClose();
      } else {
        setSnackBar({
          message: `${environment?.name} saved`,
          severity: "success",
        });
        setSnackBarState(true);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSave = async (envToSave, fromArchiveHandler) => {
    try {
      // Validate the kick_before_hint date
      if (envToSave?.kick_before_hint) {
        const parsedDate = dayjs(envToSave.kick_before_hint);
        if (!parsedDate.isValid()) {
          throw new Error("Invalid date format for kick_before_hint");
        }
        const now = dayjs();
        if (parsedDate.isAfter(now)) {
          throw new Error("kick_before_hint date cannot be in the future");
        }
      }

      const response = await save(envToSave);
      const action = fromArchiveHandler ? (envToSave?.archive ? "archived" : "unarchived") : "saved";
      if (isDialog) {
        setResultMsg(`Environment successfully ${action}`);
      } else {
        setSnackBar({
          message: `${environment?.name} ${action}`,
          severity: "success",
        });
        setSnackBarState(true);
      }
      setEnvironment(response);
      setIsDirty(false);
      setIsEditMode(false);
    } catch (error) {
      if (fromArchiveHandler) {
        setEnvironment({ ...envToSave, archive: !envToSave.archive });
      }
      handleError(error);
    }
  };

  const handleArchive = async () => {
    handleSave({ ...environment, archive: !environment.archive }, true);
    return;
  };

  const handleResultClose = async () => {
    if (onDialogClose) {
      onDialogClose();
    }
  };

  const handleError = (error) => {
    const errorMessage = getErrorMessage(error, columns);
    setErrorMsg(errorMessage);
  };

  const setProperty = (obj, key, value) => {
    return {
      ...obj,
      [key]: value,
    };
  };

  const buildFullSandboxUrl = () => {
    return `org/${currentStateData?.org?.orgId}/product/${
      environment?.product_id ?? currentStateData?.product?.productId
    }/sandboxes`;
  };

  const renderFormField = (field, i) => {
    if (!field || !environment) {
      return null; // Return null if field or environment is not defined
    }

    switch (field?.type) {
      case "date":
        return (
          <RHFieldWrapper
            key={field.field}
            label={field.headerName}
            required={field.required}
            disabled={!isEditMode}
            fullWidth={true}
          >
            <DateTimePicker
              key={field.field}
              name={field.field}
              value={environment?.[field.field] || null}
              onChange={handleChange}
              disabled={!isEditMode}
            />
          </RHFieldWrapper>
        );
      case "enhancedautocomplete":
        return field.field === "sandbox_id" && !isSandboxViewPermitted() ? (
          <Tooltip title="Permission required to list Sandboxes">
            <TextField
              required={field.required}
              label={field.headerName}
              name={field.field}
              value={environment?.sandbox_id}
              disabled={true}
              style={{ paddingBottom: "5px", width: "100%" }}
            />
          </Tooltip>
        ) : (
          <RHEnhancedAutocompleteField
            key={field.field}
            name={field.field}
            label={field.headerName}
            required={field.required}
            isDynamic={!field.defaultOptions}
            advancedSearch={false}
            idKey={field.field}
            displayValue="name"
            fullUrl={field.fullUrl ? buildFullSandboxUrl() : null}
            defaultOptions={field.defaultOptions}
            filterOnClient={true}
            value={environment?.[field.field]}
            onChange={handleChange}
            disabled={!isEditMode}
            sx={{ marginTop: "-5px", width: "100%", marginBottom: "0px", marginLeft: "0px", width: "100%" }}
          />
        );
      default:
        return (
          <TextField
            required={field.required}
            label={field.headerName}
            name={field.field}
            value={environment?.[field.field] || ""}
            onChange={handleChange}
            disabled={!isEditMode || (field.field === "base_url" && !permissionData?.isGlobalAdmin)}
            //helperText="Environment name"
            style={{ paddingBottom: "5px", width: "100%" }}
          />
        );
    }
  };

  useEffect(() => {
    if (!currentStateData) return;
    if (!currentStateData) return;
    if (isAddNew.current) {
      setIsLoading(false);
      setIsEditMode(true);
      setIsDirty(true); // remove to disable buttons until data changed
      return;
    }

    fetchData(inputEnvironment);
  }, [currentStateData]);

  return isLoading ? null : (
    <>
      <Box key="environment_form">
        {resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ padding: "20px", marginTop: "50px" }}>
              <Typography variant="h5" color="textPrimary">
                {resultMsg}
              </Typography>
              <Button variant="contained" color="primary" onClick={() => handleResultClose()}>
                Close
              </Button>
            </Box>
          </>
        )}

        {!resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ paddingBottom: "15px" }}>
              {!isAddNew.current ? (
                <Box sx={{ marginLeft: "15px" }}>
                  <IconButton
                    onClick={() => handleGoToSandbox()}
                    sx={{
                      color: "#cccccc",
                      position: "relative",
                      top: "10px",
                      marginTop: "0",
                      padding: "0 1px 0 0",
                    }}
                  >
                    <Typography variant="body2">Go To Sandbox</Typography>
                    <GoToSandboxIcon sx={{ marginLeft: "5px" }} />
                  </IconButton>
                </Box>
              ) : (
                <Typography variant="body2"></Typography>
              )}

              <Box display="flex" gap={2}>
                {isEditMode ? (
                  <>
                    <Button variant="contained" color="primary" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isDirty}
                      onClick={() => (isDialog ? handleSaveAndClose(environment) : handleSave(environment))}
                    >
                      {isDialog ? "Save & Close" : "Save"}
                    </Button>
                  </>
                ) : (
                  <>
                    {isPermitted() && (
                      <>
                        <Button variant="contained" color="primary" onClick={() => handleEdit()}>
                          Edit
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleArchive()}>
                          {environment.archive ? "Unarchive" : "Archive"}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
            {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : <></>}
            <Box className={classes.rootGameConfigPage}>
              <Box>
                <Box>
                  <Box className={classes.gameConfigFormControls}>
                    {!isAddNew.current && (
                      <Box className={classes.tableDetailForm} sx={{ gap: "0px 20px" }}>
                        {isDialog && (
                          <div style={{ marginTop: "0px", marginBottom: "20px", marginLeft: "-10px", width: "100%" }}>
                            <RHDataOnlyField
                              label="Environment ID"
                              name="environment_id"
                              value={environment?.environment_id || ""}
                            />
                          </div>
                        )}
                        <div style={{ marginTop: "-20px", marginLeft: "-10px", width: "100%" }}>
                          <RHDataOnlyField label="Product ID" name="product_id" value={environment?.product_id || ""} />
                        </div>
                        <div style={{ marginTop: "0px", marginLeft: "-10px", width: "100%" }}>
                          <RHDataOnlyField
                            label="Last Modified Timestamp"
                            name="last_modified_timestamp"
                            value={environment?.last_modified_timestamp || ""}
                          />
                        </div>
                        {/* coming soon..
                         <div style={{ marginTop: "0px", marginLeft: "-10px" }}>
                          <RHDataOnlyField
                            label="Current State"
                            name="state"
                            value={capitalize(environment?.state || "off")}
                          />
                        </div> */}
                      </Box>
                    )}
                    {/*  once hooked up, this will kick off automation to start up or bring down and environment
                    {!isAddNew.current && (
                      <Box
                        variant="contained"
                        className={classes.onOff}
                        sx={{ marginLeft: "-5px", marginTop: "-20px", marginBottom: "10px" }}
                      >
                        <Typography variant="body1" color="text.secondary">
                          Off
                        </Typography>
                        <Switch
                          checked={environment.deployed}
                          //disabled={isEditMode}
                          disabled={true}
                          onChange={handleOnOffChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <Typography variant="body1" color="text.secondary">
                          On
                        </Typography>
                      </Box>
                    )} */}

                    {columns.map((field, i) => (
                      <div key={field.field}>{renderFormField(field, i)}</div>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Snackbar
                className="snackBar"
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={snackBarState}
                message={snackBar.message}
                severity={snackBar.severity}
                key={"bottom-center"}
                autoHideDuration={3000}
                onClose={() => setSnackBarState(false)}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
