import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import RHDynamicLoadingMask from "../../../components/common/RHDynamicLoadingMask";

const CopyableField = ({ label, value }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        console.log("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="text-sm mb-2">
      <p className="font-semibold mb-1">{label}:</p>
      <p
        className="text-zinc-400 truncate cursor-pointer hover:text-zinc-300"
        onClick={handleCopy}
        title={`${value}\nClick to copy`}
      >
        {value}
      </p>
    </div>
  );
};

const EnvironmentSidebar = ({
  environment,
  onSpinActionConfirmation,
  onArchiveConfirmation,
  onNotification,
  onRefresh,
}) => {
  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const pollIntervalRef = useRef(null);

  const startPolling = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }
    pollIntervalRef.current = setInterval(onRefresh, 15000); // 15 seconds
  };

  const stopPolling = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => stopPolling(); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (!environment) return;

    const stableStates = ["on", "off", "created", "destroyed"];
    const transitionalStates = [
      "creating",
      "initializing",
      "terminating",
      "destroying",
    ];

    if (stableStates.includes(environment.state)) {
      stopPolling();
    } else if (transitionalStates.includes(environment.state)) {
      startPolling();
    }
  }, [environment]);

  const getButtonStyle = (state) => {
    if (!isActionable(state)) {
      return "bg-gray-500 hover:bg-gray-600 cursor-not-allowed";
    }
    switch (state) {
      case "on":
        return "bg-red-500 hover:bg-red-600";
      case "off":
      case "created":
        return "bg-green-500 hover:bg-green-600";
      default:
        return "bg-gray-500 hover:bg-gray-600";
    }
  };

  const getStatusColor = (state) => {
    switch (state) {
      case "on":
        return "bg-green-500";
      case "off":
      case "destroyed":
        return "bg-red-500";
      case "creating":
        return "bg-yellow-500";
      case "initializing":
        return "bg-yellow-500";
      case "terminating":
      case "destroying":
        return "bg-yellow-500";
      case "created":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusText = (state) => {
    switch (state) {
      case "on":
        return "ONLINE";
      case "off":
        return "OFFLINE";
      case "creating":
        return "CREATING";
      case "created":
        return "CREATED";
      case "initializing":
        return "INITIALIZING";
      case "terminating":
        return "TERMINATING";
      case "destroying":
        return "DESTROYING";
      case "destroyed":
        return "DESTROYED";
      default:
        return "UNKNOWN";
    }
  };

  const isActionable = (state) => {
    return ["on", "off", "created"].includes(state);
  };

  const getActionButtonText = (state) => {
    if (environment.archive && state === "off") return "Unarchive to Spin Up";
    switch (state) {
      case "on":
        return "Spin Down";
      case "off":
        return "Spin Up";
      default:
        return "Processing...";
    }
  };

  const handleSpinAction = () => {
    if (isSpinning || !isActionable(environment?.state)) {
      return;
    }

    if (environment.archive && environment.state === "off") {
      onNotification(
        "Cannot spin up an archived environment. Please unarchive it first.",
        "warning"
      );
      return;
    }

    const action = environment.state === "on" ? "down" : "up";

    setIsSpinning(true);
    onSpinActionConfirmation(
      action,
      environment.name,
      environment.environment_id
    );
    setIsSpinning(false);
    startPolling(); // Start polling after initiating a spin action
  };

  const handleSandboxClick = () => {
    setErrorMsg("");
    const connectedSandbox = currentStateData?.product?.sandboxes.find(
      (sandbox) => sandbox.sandboxId === environment.base_sandbox_id
    );
    if (!connectedSandbox) {
      setErrorMsg("Sandbox not found");
      return;
    }
    history.push(
      `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/sandbox/${connectedSandbox.shortName}`
    );
  };

  const handleGeneratedSandboxClick = () => {
    setErrorMsg("");
    const generatedSandbox = currentStateData?.product?.sandboxes.find(
      (sandbox) => sandbox.sandboxId === environment.generated_sandbox_id
    );
    if (!generatedSandbox) {
      setErrorMsg("Sandbox not found");
      return;
    }
    history.push(
      `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/sandbox/${generatedSandbox.shortName}`
    );
  };

  const handleRefresh = useCallback(() => {
    if (typeof onRefresh === "function") {
      onRefresh();
    }
  }, [onRefresh]);

  const isEnvironmentOffline =
    environment?.state === "off" || environment?.state === "destroyed";

  const handleArchiveToggle = () => {
    if (!isEnvironmentOffline) {
      onNotification(
        "You must spin down the environment before archiving.",
        "warning"
      );
      return;
    }

    onArchiveConfirmation(
      environment.name,
      environment.environment_id,
      !environment.archive
    );
  };

  if (!environment) {
    return (
      <div className="w-[296px] p-4">
        <div className="bg-zinc-800 rounded-lg">
          <RHDynamicLoadingMask loadMessage="" />
        </div>
      </div>
    );
  }

  return (
    <div className=" mx-auto ">
      {/* Header with slightly different shade */}
      <div className="h-[41px] px-4 flex items-center border-b border-zinc-700 ">
        <h2 className="font-semibold text-sm">Environment Info</h2>
      </div>

      <div className="px-4 py-6 space-y-6">
        {/* Status Section */}
        <div className="p-3 bg-zinc-800/50 rounded-lg">
          <div className="flex flex-col space-y-3">
            {/* Status indicator */}
            <div className="flex items-center">
              <span
                className={`inline-block w-4 h-4 rounded-full ${getStatusColor(
                  environment.state
                )}`}
              ></span>
              <span className="font-medium text-sm ml-2">
                Status:{" "}
                <span className="font-bold">
                  {getStatusText(environment.state)}
                </span>
              </span>
            </div>

            {/* Refresh button */}
            <button
              onClick={handleRefresh}
              className="flex items-center gap-2 text-sm px-3 py-1.5 rounded-md
                bg-zinc-700/50 hover:bg-zinc-700 transition-colors
                text-zinc-300 hover:text-zinc-100"
              title="Refresh status"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clipRule="evenodd"
                />
              </svg>
              Refresh Status
            </button>
          </div>
        </div>

        {/* Connected Sandbox Section */}
        <div>
          <p className="text-sm font-semibold mb-2">Connected Sandbox</p>
          <div className="bg-zinc-800 p-3 rounded-md space-y-2">
            <div
              onClick={handleSandboxClick}
              className="text-violet-400 hover:text-violet-300 underline cursor-pointer flex items-center justify-between"
            >
              <span className="truncate mr-2">
                {currentStateData?.product?.sandboxes.find(
                  (sandbox) => sandbox.sandboxId === environment.base_sandbox_id
                )?.sandboxName || "Unknown Sandbox"}
              </span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </div>
            <CopyableField
              label="Base Sandbox ID"
              value={environment.base_sandbox_id}
            />
          </div>
        </div>

        {/* Generated Sandbox Section */}
        {environment.sandbox_generation_enabled && environment.generated_sandbox_id && (
        <div>
          <p className="text-sm font-semibold mb-2">Generated Sandbox</p>
          <div className="bg-zinc-800 p-3 rounded-md space-y-2">
            <div
              onClick={handleGeneratedSandboxClick}
              className="text-violet-400 hover:text-violet-300 underline cursor-pointer flex items-center justify-between"
            >
              <span className="truncate mr-2">
                {currentStateData?.product?.sandboxes.find(
                  (sandbox) => sandbox.sandboxId === environment.generated_sandbox_id
                )?.sandboxName || "Unknown Sandbox"}
              </span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </div>
            <CopyableField
              label="Generated Sandbox ID"
              value={environment.generated_sandbox_id}
            />
          </div>
        </div>
        )}

        {errorMsg && (
          <div>
            <RHDynamicLoadingMask loadMessage={errorMsg} showError={true} />
          </div>
        )}

        {/* Environment Details Section - added background */}
        <div className="space-y-4 p-3 bg-zinc-800/50 rounded-lg">
          <CopyableField label="Base URL" value={environment.base_url} />
          <CopyableField label="RH Base URL" value={environment.rh_base_url} />
          <CopyableField label="Unique Name" value={environment.unique_name} />
          <CopyableField
            label="Environment ID"
            value={environment.environment_id}
          />
          <div className="text-sm">
            <p className="font-semibold mb-1">Last Modified:</p>
            <p className="text-zinc-400">
              {dayjs(environment.last_modified).format("MMMM DD YYYY HH:mm:ss")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EnvironmentSidebar);
