// const API_BASE = "http://localhost:8000/v1/armada";

const API_BASE =
  process.env.NODE_ENV === "production"
    ? `${window.env.REACT_APP_API_BASE_PATH}/v1`
    : `${process.env.REACT_APP_API_BASE_PATH}/v1`;


export async function fetchDirectly(args, params) {
  let paramsString;

  if (params) {
    const searchParams = new URLSearchParams(Object.entries(params));
    paramsString = "?" + searchParams.toString();
  }

  let response = await fetch(
    `${API_BASE}/${args?.fullUrl ?? `${args.endpoint}${args.dataId ? "/" + args.dataId : ""}`}${paramsString ?? ""}`,
    {
      method: args.method,
      headers: new Headers({
        Authorization: `Bearer ${args?.token}`,
        "Content-Type": "application/json",
      }),
      body: args.body ? JSON.stringify(args.body) : null,
    }
  );
  if (response?.ok) {
    return response.status === 204 ? [] : await response.json();
  } else {
    let newError = new Error("Something went wrong");
    newError.response = { data: { desc: JSON.stringify(await response.json()) } };
    throw newError;
  }
}


