import React from "react";

const BasicSelect = ({ label, name, value, onChange, options = [], required, disabled, placeholder, inline=false, fullWidth=true }) => (
  <div className="mb-4">
    <label className={`${inline ? "inline pr-2" : "block"} text-sm font-medium text-zinc-400 mb-1`}>{label}</label>
    <select
      name={name}
      value={value || ""}
      onChange={onChange}
      required={required}
      disabled={disabled}
      className={`${fullWidth ? "w-full" : ""} bg-zinc-800 text-white border border-zinc-700 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-violet-500`}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options?.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </select>
  </div>
);

BasicSelect.defaultProps = {
  options: [],
  required: false,
  disabled: false,
};

export default BasicSelect;
