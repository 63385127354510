import { useState, useEffect } from "react";
import { Box} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import RHConfirmDialog from "../../../components/common/RHConfirmDialog";
import { useTableStyles } from "./styles";

export default function TerraformRecordTable(props) {
  const {

    rowKey,
    columns,
    tableData,
    initialState,
    nestedDataToUpdate,
    retrieveSelectedRows,
    onSelectedRowsChanged,
    selectionModel,
    filterMode,
    onFilterChanged,
    rowCount,
    paginationMode,
    onPaginationChanged,
    sortingMode,
    onSortModelChanged,
    disableRowClick,
  } = props;


  const [localData, setLocalData] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [errorMsg, setErrorMsg] = useState();

  const classes = useTableStyles();

  let defaultInitialState;

  if (!initialState) {
    defaultInitialState = {
      pagination: {
        paginationModel: {
          pageSize: 50,
        },
      },
      columns: {
        columnVisibilityModel: {
          last_modified_timestamp: false,
        },
      },
      sorting: {
        sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
      },
    };
  }

  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  useEffect(() => {
    if (nestedDataToUpdate && tableData) {
      setLocalData(tableData[nestedDataToUpdate]);
    } else if (tableData) {
      setLocalData(tableData);
      selectionModel && setRowSelectionModel(selectionModel);
    }
  }, [tableData]);

  const toggleShowDetail = (state) => {
    setShowDetail(state);
    setPersistentErrorMsg();
  };

  const handleRowClick = (params) => {
    const item = params.row;
    retrieveSelectedRows && retrieveSelectedRows(item);
    toggleShowDetail(true);
  };

  const setPersistentErrorMsg = (msg) => {
    setErrorMsg(msg);
  };



  const onFilterChangeHandler = async (filterModel) => {
    if (filterMode == "server") {
      onFilterChanged(filterModel);
    }
  };

  const onPaginationChangeHandler = async (options) => {
    if (paginationMode == "server") {
      if (onPaginationChanged) {
        onPaginationChanged(options);
      }
    }
  };

  const onSortModelChangeHandler = async (sortModel) => {
    if (sortingMode == "server") {
      if (onSortModelChanged) {
        onSortModelChanged([...sortModel]);
      }
    }
  };

  const showDetailForm = () => {
    const rowIsSelectedOrIsNewItem = newItemCreated || rowSelectionModel?.length > 0;
    return showDetail && rowIsSelectedOrIsNewItem;
  };

  return (
    <>
      <Box className={classes.rootConfigPage}>
        {localData && (
          <DataGrid
            {...props}
            className={classes.table}
            initialState={initialState ?? defaultInitialState}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            getRowId={(row) => row[rowKey]}
            onRowClick={disableRowClick ? () => {} : handleRowClick}
            rows={localData}
            columns={columns}
            filterMode={filterMode ?? "client"}
            onFilterModelChange={onFilterChangeHandler}
            pagination
            paginationMode={paginationMode ?? "client"}
            onPaginationModelChange={onPaginationChangeHandler}
            sortingMode={sortingMode ?? "client"}
            onSortModelChange={onSortModelChangeHandler}
            rowCount={rowCount}
            autoHeight
            editMode="row"
            onRowSelectionModelChange={(items) => {
              setRowSelectionModel(items);
              onSelectedRowsChanged && onSelectedRowsChanged(items);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              bgcolor: "background.paper",
              border: "none",
              minWidth: 0,
              p: 1,
            }}
          />
        )}
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
      </Box>
    </>
  );
}
