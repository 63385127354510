export function createColumnDefs() {
  return [
  {
    field: "terraform_user_name",
    headerName: "Username",
    minWidth: 200,
    required: true,

    flex: 1,
  },
  {
    field: "terraform_job_timestamp",
    headerName: "Timestamp",
    minWidth: 300,
    required: true,
    flex: 1,
  },

  {
    field: "terraform_vm_routing",
    headerName: "Routing",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_site",
    headerName: "Site",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_subnet_range",
    headerName: "Subnet Range",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_zone",
    headerName: "Zone",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_count",
    headerName: "Total VM Count",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_fleet",
    headerName: "Fleet",
    minWidth: 200,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_org",
    headerName: "Org",
    minWidth: 300,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_job_record_id",
    headerName: "Record Id",
    minWidth: 300,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_region_id",
    headerName: "Region ID",
    minWidth: 300,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_disk_id",
    headerName: "Disk ID",
    minWidth: 300,
    flex: 1,
    required: true,
  },
  {
    field: "terraform_vm_size_id",
    headerName: "Vm Size ID",
    minWidth: 300,
    flex: 1,
    required: true,
  },
  {
    field: "terraform_vm_resource_group_id",
    headerName: "Resource Group ID",
    minWidth: 300,
    flex: 1,
    required: true,
  },
  {
    field: "terraform_vm_operating_system_id",
    headerName: "Operating System ID",
    minWidth: 300,
    flex: 1,
    required: true,
  },
  {
    field: "terraform_vm_product_guid",
    headerName: "Product GUID",
    minWidth: 300,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_rhdeployment",
    headerName: "Rhdeployment GUID",
    minWidth: 300,
    required: true,
    flex: 1,
  },
  {
    field: "terraform_vm_sandbox_guid",
    headerName: "Sandbox GUID",
    minWidth: 300,
    required: true,
    flex: 1,
  },
    {
      field: "terraform_job_raw_json",
      headerName: "Raw JSON",
      minWidth: 300,
      required: true,
      flex: 1,
    },




]}

export class NewEntry {
  name = "";
}
