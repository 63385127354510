import React, { useState, useEffect, useCallback, useMemo } from "react";
import CustomInput from "./CustomInput";
import BasicSelect from "./SelectComponents/BasicSelect";
import BasicMultiSelect from "./SelectComponents/BasicMultiSelect"; // Add this import
import CustomDateTimeInput from "./CustomDateTimeInput";
import CustomBooleanInput from "./CustomBooleanInput";
import DynamicSelect from "./SelectComponents/DynamicSelect";
import DynamicMultiSelect from "./SelectComponents/DynamicMultiSelect";
import ChipInput from "./ChipInput";
import ErrorBoundary from "./ErrorBoundary";
import dayjs from "dayjs";
import SkeletonLoader from "./SkeletonLoader";
import SubtitleComponent from "./SubtitleComponent";
import { Link } from "react-router-dom"; // Add this import

// Helper functions
const setAutocompleteValue = (fieldValue) => {
  let result;
  if (Array.isArray(fieldValue)) {
    result = fieldValue.map((item) => (typeof item === "object" && item !== null ? item : { id: item, name: item }));
  } else if (fieldValue !== null && fieldValue !== undefined) {
    result = typeof fieldValue === "object" ? fieldValue : { id: fieldValue, name: fieldValue };
  } else {
    result = null;
  }
  return result;
};

const checkDisabled = (field) => {
  // Implement the logic to check if the field is disabled
  // This is a placeholder and should be adjusted based on your specific requirements
  return field.disabled || false;
};


const MemoizedField = React.memo(
  ({ field, value, onFieldChange, sandboxId, pageData, rowKey }) => {
    const handleChange = useCallback(
      (eventOrValue) => {
        let name, newValue;

        if (eventOrValue && eventOrValue.target) {
          // It's an event object
          const { name: fieldName, value, type, checked } = eventOrValue.target;
          name = fieldName;
          if (type === "checkbox") {
            newValue = checked;
          } else if (type === "datetime") {
            newValue = value ? value : null;
          } else if (Array.isArray(value)) {
            newValue = value;
          } else {
            newValue = value?.trim() || null;
          }
        } else {
          // It's a direct value (e.g., from CustomBooleanInput)
          name = field.field;
          newValue = eventOrValue;
        }

        onFieldChange(name, newValue);
      },
      [onFieldChange, field.field]
    );

    const commonProps = {
      id: field.field,
      name: field.field,
      value: value ?? null,
      onChange: handleChange,
      required: field.required,
      className: field.type !== "boolean" ? "w-full bg-gray-700 text-white border-gray-600 rounded-md" : "",
    };

    switch (field.type) {
      case "select":
        return <BasicSelect {...commonProps} options={field.options} />;
      case "multiselect":
        return (
          <BasicMultiSelect
            {...commonProps}
            options={field.options}
            value={Array.isArray(value) ? value : value ? [value] : []}
            placeholder={`Select ${field.headerName}`}
          />
        );
      case "datetime":
        return <CustomDateTimeInput {...commonProps} value={value ? dayjs(value).format("YYYY-MM-DDTHH:mm") : ""} />;
      case "boolean":
        return (
          <div className="">
            <CustomBooleanInput {...commonProps} label={field.headerName} />
          </div>
        );
      case "dynamicselectfield":
        const isMultiSelect =
          (field.parentKey && pageData?.Columns?.[field.parentKey]?.children?.[field.field]?.type === "array") ||
          pageData?.Columns?.[field.field]?.type === "array";
        const isStaticSelect = !pageData?.dynamicSelectFields?.[field.field]?.tableData;
        const DynamicSelectComponent = isMultiSelect || isStaticSelect ? DynamicMultiSelect : DynamicSelect;
        const autocompleteValue = setAutocompleteValue(value);

        return (
          <ErrorBoundary key={field.field}>
            <DynamicSelectComponent
              {...commonProps}
              api={field.api}
              lookupId={field.lookupId}
              preLoaded={field.preLoad}
              hasError={false}
              preLoadedOptions={field.preLoad ? field.options : null}
              responseDataName={field.responseDataName}
              selectedItem={autocompleteValue}
              disabled={checkDisabled(field)}
              row_key={rowKey}
              pageData={pageData}
              sandboxId={sandboxId}
              required={true}
              value={autocompleteValue}
              retro={false}
            />
          </ErrorBoundary>
        );
      case "subtitle":
        return <SubtitleComponent text={field.headerName} className="mb-2" />;
      case "chip":
        return (
          <ChipInput
            {...commonProps}
            label={field.headerName}
            placeholder={`Enter ${field.headerName}`}
            value={Array.isArray(value) ? value : value ? [value] : []}
            onChange={(newValue) => onFieldChange(field.field, newValue)}
          />
        );
      default:
        return <CustomInput {...commonProps} placeholder={`Enter ${field.headerName}`} />;
    }
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.field === nextProps.field &&
      prevProps.sandboxId === nextProps.sandboxId &&
      prevProps.rowKey === nextProps.rowKey
    );
  }
);

const TerraformConfigComponent = ({
  fields,
  data,
  onDataChange,
  isLoading,
  onSave,
  errorMsg,
  pageData = {},
  rowKey,
}) => {
  const [isTableView, setIsTableView] = useState(typeof window !== "undefined" ? window.innerWidth >= 800 : false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => setShowSkeleton(false), 50);
      return () => clearTimeout(timer);
    } else {
      setShowSkeleton(true);
    }
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => setIsTableView(window.innerWidth >= 800);
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const handleFieldChange = useCallback(
    (name, value) => {
      onDataChange((prevData) => ({ ...prevData, [name]: value }));
    },
    [onDataChange]
  );

  const renderField = useCallback(
    (field) => {
      if (field.type === "subtitle") {
        return <SubtitleComponent key={field.field} text={field.headerName} className="mb-2" />;
      }

      return (
        <div key={field.field} className={`mt-2${isTableView ? "flex items-start" : ""}`}>
          <label
            htmlFor={field.field}
            className={`${isTableView ? "w-40 min-w-[160px] pr-4" : "block mb-2"} text-sm font-medium text-gray-400`}
          >
            {field.type !== "subtitle" && (
              <>
                {field.headerName}
                {field.required && <span className="text-red-500 ml-1">*</span>}
              </>
            )}
          </label>
          <div className={`${isTableView ? "flex-1" : ""}`}>
            <MemoizedField
              field={field}
              value={data[field.field]}
              onFieldChange={handleFieldChange}
              pageData={pageData}
              rowKey={rowKey}
            />
          </div>
        </div>
      );
    },
    [isTableView, data, handleFieldChange, pageData, rowKey]
  );

  const memoizedFields = useMemo(() => fields.map(renderField), [fields, renderField]);

  return (
    <div className={`${isTableView ? "min-w-[300px]" : ""} w-1/2 `}>

      {errorMsg && <div className="bg-red-500 text-white p-3 rounded-md mb-4">{errorMsg}</div>}

      <div className="text-sm relative justify-center">
        <div
          className={`absolute inset-0 z-10 transition-opacity duration-100 ${
            showSkeleton ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          {fields.map((field) => (
            <SkeletonLoader
              key={field.field}
              type={field.type === "select" ? "select" : "input"}
              className="opacity-50"
            />
          ))}
        </div>
        <div className={`transition-opacity duration-100 ${showSkeleton ? "opacity-0" : "opacity-100"} `}>
          {memoizedFields}
        </div>

        <div className="flex justify-center">
          <button
            className={`px-4 py-2 rounded-md transition-opacity bg-violet-500 text-white hover:bg-violet-600 mr-4`}
            onClick={onSave}
          >
            Run Terraform Job
          </button>

            <Link
              to="/admin/terraform/job-record"
              className="px-4 py-2 rounded-md transition-opacity bg-zinc-800 text-white hover:bg-violet-600 "
            >
              <span>Terraform Job History</span>
            </Link>

        </div>
      </div>
    </div>
  );
};

export default React.memo(TerraformConfigComponent);
