import JsonField from "./JsonField";
import BooleanInput from "./Boolean";
import ReadOnlyField from "./ReadOnlyField";
import NumberField from "./NumberField";
import RepeaterField from "./RepeaterField";
import TextField from "./TextField";
import SingleDynamicSelect from "./SingleDynamicSelect";
import BasicSelect from "./BasicSelect";
import DateTime from "./DateTime";

export default function FormField({
  field,
  rowKey,
  fieldGroup,
  onChange,
  originalValue,
  description,
  disabled,
  pageData,
  sandboxId,
  onCheckToEnable,
}) {
  function checkDisabled(field) {
    if (disabled) {
      return true;
    }
    if (field.editable === false) {
      return true;
    }
    if (field.alwaysDisabled) {
      return true;
    }
    if (field.field === rowKey && field.field !== "platform") {
      return false;
    }
    return false;
  }

  function handleValueChange(e, field, type) {
    // I swear the checkbox is cranky and needs this.
    const newVal = type === "checkbox" ? e.target.checked : e.target.value;
    const newFieldGroup = { ...fieldGroup, [field]: newVal };
    onChange(newFieldGroup, { field, type });
  }

  switch (field?.type) {
    case "singleDynamicSelect":
      return (
        <SingleDynamicSelect
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={handleValueChange}
          pageData={pageData}
          sandboxId={sandboxId}
          onCheckToEnable={onCheckToEnable}
        />
      );
    case "boolean":
      return (
        <BooleanInput
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={handleValueChange}
          onCheckToEnable={onCheckToEnable}
        />
      );
    case "basicSelect":
      return (
        <BasicSelect
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          options={field.options}
          onCheckToEnable={onCheckToEnable}
        />
      );
    case "metadata":
      return (
        <ReadOnlyField
          type={field.type}
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          inputProps={{ maxLength: 500 }}
          description={field.info ?? description}
        />
      );
    case "datetime":
      return (
        <DateTime
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
        />
      );
    default:
      return (
        <TextField
          type={field.type}
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          inputProps={{ maxLength: 500 }}
          description={field.info ?? description}
          onCheckToEnable={onCheckToEnable}
        />
      );
  }
}
