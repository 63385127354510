import { useContext, useEffect, useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import AccountContext from "../../contexts/AccountContext";
import { readCookie } from "../../common/helpers";
import LockPersonIcon from "@mui/icons-material/LockPerson";
//import { TableauEventType } from 'https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.js';
import { useLocation } from "react-router-dom";

export default function Tableau() {
  const location = useLocation();
  const tableauUrl = useMemo(() => {
    const pathSegment = location.pathname.split("/").slice(-1)[0];
    console.log("Current pathSegment:", pathSegment);
    console.log("Full pathname:", location.pathname);

    switch (pathSegment) {
      case "Live Executive Dashboard":
        console.log("Matched Live Executive Dashboard");
        return "https://prod-useast-a.online.tableau.com/t/hirezstudios/views/LiveExecutiveDashboard_17369795088570/ActiveUsers";
      case "Active Users":
        console.log("Matched Active Users");
        return "https://prod-useast-a.online.tableau.com/t/hirezstudios/views/ActiveUsersDashboardDAUMAU/Sheet1";
      case "Sales Dashboard":
        console.log("Matched Sales Dashboard");
        return "https://prod-useast-a.online.tableau.com/t/hirezstudios/views/SMITE2TotalSales/TotalSalesByDay";
      default:
        console.log("No match - using default");
        return "https://prod-useast-a.online.tableau.com/t/hirezstudios/views/ActiveUsersDashboardDAUMAU/Sheet1";
    }
  }, [location]);

  async function injectScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = src;
      script.type = "module";
      script.addEventListener("load", resolve);
      script.addEventListener("error", () => reject("Error loading script."));
      script.addEventListener("abort", () => reject("Script loading aborted."));
      document.head.appendChild(script);
    });
  }

  useEffect(() => {
    injectScript("https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.js")
      .then(() => {
        console.log("Tableau script injected");
      })
      .catch((error) => {
        console.error("Error injecting Tableau script:", error);
      });
  }, []);

  return (
    <div>
      <tableau-viz src={tableauUrl} device="desktop" toolbar="hidden"></tableau-viz>
    </div>
  );
}
