export const columns = [
  {
    field: "name",
    headerName: "Resource Group Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "subnet",
    headerName: "Subnet range",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  }
];

export class NewEntry {
  name = "";
}
