import { useState } from "react";
import { useLayout } from "contexts/LayoutContext";
import Tooltip from "components/common/FormField/Tooltip";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { CalendarIcon } from "assets/svgs";

dayjs.extend(isSameOrAfter);

export default function DateTime({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  disabled,
  type = "text",
  description,
  hasError,
}) {
  const { tableSize } = useLayout();
  const [error, setError] = useState("");

  const validateDate = (date) => {
    if (date && !dayjs(date).isSameOrAfter(dayjs(), "second")) {
      setError("Date must be in the future");
      return false;
    } else {
      setError();
      return true;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value ? dayjs(e.target.value).format("YYYY-MM-DDTHH:mm:ssZ") : "";
    if (validateDate(newValue)) {
      onChange({ target: { name, value: newValue } });
    }
  };

  const handleNowClick = () => {
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
    if (validateDate(now)) {
      onChange({ target: { name, value: now } });
    }
  };

  const handleClearClick = () => {
    setError("");
    onChange({ target: { name, value: "" } });
  };

  return (
    <div className="mb-3 container-query-formfield">
      <div className="cq-wrapper">
        <label
          htmlFor={name}
          className={`block font-medium text-zinc-300 font-lato ${
            tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
          } ${disabled ? "opacity-70" : ""}`}
        >
          <Tooltip title={description} placement="left" disableInteractive>
            <p className="mb-1 cq-label">
              <span>{`${label} ${required ? "*" : ""}`}</span>
            </p>
          </Tooltip>
        </label>
        <div className={`flex cq-input ${disabled ? "opacity-60" : ""}`}>
          <div className="flex relative w-full">
            <input
              type="datetime-local"
              name={name}
              value={value ? dayjs(value).format("YYYY-MM-DDTHH:mm") : ""}
              min={dayjs().format("YYYY-MM-DDTHH:mm")}
              onChange={handleChange}
              required={required}
              disabled={disabled}
              className={`
                flex-grow text-white/90 border border-zinc-600 rounded-l-md p-2 
                placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm 
                [&::-webkit-calendar-picker-indicator]:opacity-0
                [color-scheme:dark]
                ${tableSize}
              `}
            />
            <CalendarIcon className="text-white/90 bg-transparent absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none" />
          </div>
          <button
            type="button"
            onClick={handleNowClick}
            disabled={disabled}
            className="bg-zinc-700 text-white px-4 py-2 hover:bg-zinc-600 "
          >
            Now
          </button>
          <button
            type="button"
            onClick={handleClearClick}
            disabled={disabled}
            className="bg-zinc-700 text-white px-4 py-2 rounded-r-md hover:bg-zinc-600 "
          >
            Clear
          </button>
        </div>
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
}
