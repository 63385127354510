import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import AccountContext from "../../contexts/AccountContext";
import { readCookie } from "../../common/helpers";

import LockPersonIcon from "@mui/icons-material/LockPerson";
import DashboardWidget from "../../components/DashboardWidget";

const isDev = window.env.REACT_APP_API_BASE_PATH !== "https://developer.rallyhere.gg/api";

const INSIGHTS_V2_LINK = isDev ? "https://insights-dev.rallyhere.dev" : "https://insights.rallyhere.gg";

const useStyles = makeStyles((theme) => {
  return {
    rootSandboxPage: {
      color: "rgba(255, 255, 255, 0.7)",
      maxWidth: "1452px",
      width: "100%",
      margin: "5px",

    },
    h4: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontSize: "24px",
      fontWeight: 400,
      margin: "0 0 20px",
    },
    widgets: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },
      gap: "24px",
    },
    widgetsAdditionalRows: {
      marginTop: "20px",
    },
    panels: {
      maxWidth: "468px",
      minWidth: "468px",
      minHeight: "368px",
      maxHeight: "468px",
    },
    titleWithButtons: {
      display: "flex",
      width: "100%",
      flexFlow: "row",
    },
    toggleButtonGroup: {
      marginLeft: "auto",
      marginBottom: "20px",
      height: "36px",
    },
  };
});

export default function RHEnvironmentPage() {
  const classes = useStyles();

  const [timespans, setTimespans] = useState();
  const [ccuTimespans, setCCUTimespans] = useState();
  const [dauTimespans, setDAUTimespans] = useState();

  const [selectedTimespan, setSelectedTimespan] = useState("2d");
  const [grafanaTimeSpan, setGrafanaTimeSpan] = useState("from=now-2d&to=now");
  const [grafanaDAUTimeSpan, setGrafanaDAUTimeSpan] = useState("from=now-2d&to=now");

  const { currentStateData, permissionData } = useContext(AccountContext);

  function getDateWithOffset(offsetDays, offsetWeeks, includeTime = false) {
    const now = new Date();

    // Calculate the offset based on days and weeks
    const totalOffsetDays = offsetDays + offsetWeeks * 7;
    now.setDate(now.getDate() - totalOffsetDays);

    // Set time to beginning of the day if includeTime is false
    if (!includeTime) {
      now.setHours(0, 0, 0, 0);
    }

    return now.toISOString();
  }

  function getDateAtTargetDay(targetDay, offsetWeeks = 0, includeTime = false) {
    const now = new Date();

    const currentDay = now.getDay();
    const daysUntilTarget = (currentDay - targetDay + 7) % 7;

    const totalOffsetDays = daysUntilTarget + offsetWeeks * 7;
    now.setDate(now.getDate() - totalOffsetDays);

    if (!includeTime) {
      now.setHours(0, 0, 0, 0);
    }

    return now.toISOString();
  }

  function getTimespans() {
    const now = new Date();
    const thisMorning = new Date();
    thisMorning.setHours(0, 0, 0, 0);

    // prettier-ignore
    return {
      today: `from_date=${thisMorning.toISOString()}&to_date=${now.toISOString()}`,
      yesterday: `from_date=${getDateWithOffset(1, 0)}&to_date=${getDateWithOffset(1, 0, true)}`,
      week_this: `from_date=${getDateAtTargetDay(1, 0)}&to_date=${now.toISOString()}`,
      week_last: `from_date=${getDateAtTargetDay(1, 1)}&to_date=${getDateWithOffset(7, 0, true)}`,
    };
  }

  function getMinutesAgo(minutesAgo, d) {
    const ret = new Date(d);
    ret.setMinutes(d.getMinutes() - minutesAgo);
    return ret;
  }

  function getDaysAgo(daysAgo, d) {
    const ret = new Date(d);
    ret.setDate(d.getDate() - daysAgo);
    return ret;
  }

  function noTime(d) {
    const ret = new Date(d);
    ret.setHours(0, 0, 0, 0);
    return ret.toISOString();
  }

  function withTime(d) {
    return d.toISOString();
  }

  function getCCUTimespans() {
    const now = new Date();
    const windowMinutes = 2;

    const timespans = {
      today: `from_date=${withTime(getMinutesAgo(windowMinutes, now))}&to_date=${withTime(now)}`,
      yesterday: `from_date=${withTime(getDaysAgo(1, getMinutesAgo(windowMinutes, now)))}&to_date=${withTime(getDaysAgo(1, now))}`,
      week_this: `from_date=${withTime(getMinutesAgo(windowMinutes, now))}&to_date=${withTime(now)}`,
      week_last: `from_date=${withTime(getDaysAgo(7, getMinutesAgo(windowMinutes, now)))}&to_date=${withTime(getDaysAgo(7, now))}`,
    };

    return timespans;
  }

  function getDAUTimespans() {
    const now = new Date();

    const timespans = {
      today: `from_date=${noTime(now)}&to_date=${withTime(now)}`,
      yesterday: `from_date=${noTime(getDaysAgo(1, now))}&to_date=${noTime(now)}`,
      week_this: `from_date=${noTime(now)}&to_date=${withTime(now)}`,
      week_last: `from_date=${noTime(getDaysAgo(7, now))}&to_date=${noTime(getDaysAgo(6, now))}`,
    };

    return timespans;
  }

  function isPermitted() {
    const permission_to_check = "environment:analytics:view";
    if (
      permissionData?.isGlobalAdmin ||
      currentStateData?.environment?.permissions?.includes(permission_to_check) ||
      permissionData?.permissions.find(
        (x) =>
          (x.sandbox_id == currentStateData?.environment?.environmentId && x.permission_id == permission_to_check) ||
          (x.product_id == currentStateData?.product?.productId && x.permission_id == permission_to_check) ||
          (x.org_id == currentStateData?.org?.orgId && x.permission_id == permission_to_check)
      )
    ) {
      return true;
    }
    return false;
  }

  function getFirstSliceOfEnvironmentId() {
    const splitId = currentStateData?.environment?.environmentId?.split("-");
    if (splitId && splitId.length > 1) {
      return splitId[0];
    }
    return null;
  }

  function calculateGrafanaTimespan(event, selectedTimespan) {
    if (selectedTimespan === null) {
      setSelectedTimespan("30m");
      setGrafanaTimeSpan(`from=now-30m&to=now`);
      calculateGrafanaDAUTimespan(event, "30m");
    } else {
      setSelectedTimespan(selectedTimespan);
      setGrafanaTimeSpan(`from=now-${selectedTimespan}&to=now`);
      calculateGrafanaDAUTimespan(event, selectedTimespan);
    }
  }
  function calculateGrafanaDAUTimespan(event, selectedTimespan) {
    if (selectedTimespan.includes("d")) {
      const days = parseInt(selectedTimespan); //this parses until the d
      const now = new Date();
      now.setDate(now.getDate() - days);
      now.setHours(0, 0, 0, 0);
      setGrafanaDAUTimeSpan(`from=${now.valueOf()}&to=now`);
    } else {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      setGrafanaDAUTimeSpan(`from=${now.valueOf()}&to=now`);
    }
  }

  function getRhBaseUrl() {
    let rhBaseUrl = currentStateData?.environment?.rhBaseUrl;
    if (!rhBaseUrl.startsWith("https://")) {
      rhBaseUrl = `https://${rhBaseUrl}`;
    }
    return rhBaseUrl;
  }

  useEffect(() => {
    calculateGrafanaDAUTimespan(null, "1d");
  }, []);

  useEffect(() => {
    if (!currentStateData) return;
    const timespans = getTimespans();
    setTimespans(timespans);
    const ccuTimespans = getCCUTimespans();
    setCCUTimespans(ccuTimespans);
    const dauTimespans = getDAUTimespans();
    setDAUTimespans(dauTimespans);
  }, [currentStateData]);

  return !isPermitted() ? (
    <>
      <Tooltip title="You do not have permission to view the dashboard">
        <LockPersonIcon sx={{ color: "#5E5E5E" }} fontSize="large" />
      </Tooltip>
    </>
  ) : (
    <>
      <div className={classes.rootSandboxPage}>
        {grafanaTimeSpan &&
          currentStateData?.environment?.environmentId &&
          ccuTimespans &&
          dauTimespans &&
          timespans && (
            <>
              <div className={classes.widgets}>
                {/*CCU*/}
                <iframe
                  className={classes.panels}
                  src={`${INSIGHTS_V2_LINK}/d-solo/${getFirstSliceOfEnvironmentId()}-ccu-secrets/user-counts?refresh=30s&${grafanaTimeSpan}&theme=dark&panelId=1&auth_token=${readCookie("authSession")}&orgId=${currentStateData?.org?.grafanaOrgId}`}
                />
                {/*DAU*/}
                <iframe
                  className={classes.panels}
                  src={`${INSIGHTS_V2_LINK}/d-solo/${getFirstSliceOfEnvironmentId()}-ccu-secrets/user-counts?refresh=30s&${grafanaDAUTimeSpan}&theme=dark&panelId=3&auth_token=${readCookie("authSession")}&orgId=${currentStateData?.org?.grafanaOrgId}`}
                />
                {/*New Players*/}
                <iframe
                  className={classes.panels}
                  src={`${INSIGHTS_V2_LINK}/d-solo/${getFirstSliceOfEnvironmentId()}-ccu-secrets/user-counts?refresh=30s&${grafanaTimeSpan}&theme=dark&panelId=2&auth_token=${readCookie("authSession")}&orgId=${currentStateData?.org?.grafanaOrgId}`}
                />
              </div>
              <div style={{ marginTop: "30px" }}>
                <h4 className={classes.h4}>Monetization Overview</h4>
                <div className={classes.widgets}>
                  <iframe
                    className={classes.panels}
                    src={`${INSIGHTS_V2_LINK}/d-solo/${getFirstSliceOfEnvironmentId()}-purchases/purchases?refresh=30s&${grafanaTimeSpan}&theme=dark&panelId=4&auth_token=${readCookie("authSession")}&orgId=${currentStateData?.org?.grafanaOrgId}`}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
}
