import { useEffect, useState } from "react";
import { fetchDirectly } from "../helpers";
import Snackbar from "@mui/material/Snackbar";
import { useAuth } from "contexts/AuthContext";
import { createColumnDefs, NewEntry } from "./colDefs";
import TerraformRecordTable from "../TerraformRecordTable";


export default function ArmadaTerraformJobRecord() {
  const ROW_KEY = "terraform_job_record_id";
  const ENDPOINT = "terraform/terraform_job_record";
  const { getAccessTokenSilently } = useAuth();

  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const [data, setData] = useState();

  async function readData() {
    let response = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "GET",
      token: await getAccessTokenSilently(),
    });
    let newData = []
    response.map(x => {
      // im not proud of this. This is correcting the JSON quote format.
      let newString = x.terraform_job_raw_json
        .replaceAll("'", "|")
        .replaceAll('|', '"')
        .replaceAll("UUID(", "")
        .replaceAll(")", "")
      let newObj = {...x, ...JSON.parse(newString)}
      newData.push(newObj);
    })
    setData(newData)
  }

  const columns = createColumnDefs();

  function createVendorColVisibilityModel() {
    var keepers = [
      ROW_KEY,
      "terraform_user_name",
      "terraform_job_timestamp",
      "terraform_vm_region_id",
      "terraform_vm_disk_id",
      "terraform_vm_size_id",
      "terraform_vm_resource_group_id",
      "terraform_vm_operating_system_id",
      "terraform_job_record_id",
      "terraform_vm_count",
      "terraform_vm_fleet",
      "terraform_vm_org",
      "terraform_vm_product_guid",
      "terraform_vm_rhdeployment",
      "terraform_vm_routing",
      "terraform_vm_sandbox_guid",
      "terraform_vm_site",
      "terraform_vm_subnet_range",
      "terraform_vm_zone",

    ];
    return columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((dict, el) => ((dict[el] = false), dict), {});
  }

  function customFilterFunc(field) {
    return ![].includes(field.field);
  }

  useEffect(() => {
    readData();
  }, []);

  return !data ? null : (
    <>
      <TerraformRecordTable
        title="Terraform Job Record"
        friendlyNameKey="name"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data && data}
        textFieldFilter={customFilterFunc}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        newModel={NewEntry}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          columns: {
            columnVisibilityModel: createVendorColVisibilityModel(),
          },
          sorting: {
            sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
          },
        }}
      />
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
