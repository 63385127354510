import React, { useState, useEffect, useMemo, useRef } from "react";
import { fetchDirectly } from "../../../common/apiUtils";
import { useAuth } from "../../../contexts/AuthContext";
import { createColumnHelper } from "@tanstack/react-table";
import { useLayout } from "../../../contexts/LayoutContext";
import BasicSelect from "../../../views/ControlPanel/components/SelectComponents/BasicSelect";
import DataTable from "../../../components/common/datatable";
import LoadingMask from "../../../components/common/DynamicLoadingMask";
import RightDrawer from "../../../components/common/RightDrawer";
import {
  CopyIcon,
} from "../../../assets/svgs";
import { motion } from "framer-motion";
export default function ArmadaClientCredentials() {

  const { defaultDrawerWidth } = useLayout();
  const columnHelper = createColumnHelper();
  const { getAccessTokenSilently } = useAuth();
  const [data, setData] = useState();
  const [orgData, setOrgData] = useState();
  const [credData, setCredData] = useState();
  const [credMissingMessage, setCredMissingMessage] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [drawerLoadingMessage, setDrawerLoadingMessage] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [copiedField, setCopiedField] = useState(null);

  let isResizing = useRef(false);

  async function readData() {
    const org_filter = selectedOrg ? `&org_ids=${selectedOrg}` : "";
    const response = await fetchDirectly({
          //https://developer.platform-dev.rally-here.io/api/v1/admin/dashboard?cursor=0&page_size=20
          fullUrl: `v1/admin/dashboard?page_size=100${org_filter}`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
    setData(response["data"]);
  }

  async function readOrgData() {
    const response = await fetchDirectly({
          fullUrl: `v1/org`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
    setOrgData(response);
  }

  async function readCreds(environment_id) {
    setCredMissingMessage(null)
    try {
    const response = await fetchDirectly({
          fullUrl: `v1/environment/${environment_id}/client/armada`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
      setCredData(response);
    }
    catch (error) {
      setCredMissingMessage(error?.response?.data?.desc);
    }
  }

  useEffect(() => {
    if (Object.keys(rowSelectionModel).length > 0) {
      setDrawerWidth(defaultDrawerWidth);
      setCredData(null);
      const original = selectedRow?.original
      if (original) {
        readCreds(original.cust_env_id).then(r => {});
        setDrawerLoadingMessage(false);
      }
    } else {
      setDrawerWidth(0);
    }
  }, [selectedRow, rowSelectionModel]);

  useEffect(() => {
   readData().then(r => {});
  }, [selectedOrg]);

  useEffect(() => {
   readOrgData().then(r => {});
  }, []);

  const columns = useMemo(
    () => [
      // columnHelper.accessor("cust_env_id", {
      //   cell: (info) => info.getValue(),
      //   header: () => <span>ID</span>,
      //   minSize: 250,
      //   maxSize: 300,
      // }),
      columnHelper.accessor("customer_org", {
        cell: (info) => info.getValue(),
        header: () => <span>Organization</span>,
        minSize: 250,
        maxSize: 300,
      }),
      columnHelper.accessor("customer_product", {
        cell: (info) => info.getValue(),
        header: () => <span>Product</span>,
        minSize: 250,
        maxSize: 300,
      }),
      columnHelper.accessor("customer_environment", {
        cell: (info) => info.getValue(),
        header: () => <span>Environment</span>,
        minSize: 250,
        maxSize: 300,
      }),
      columnHelper.accessor("customer_sandbox", {
        cell: (info) => info.getValue(),
        header: () => <span>Environment Sandbox</span>,
        minSize: 250,
        maxSize: 300,
      }),
    ],
    [],
  );

  function copySecret(credData) {
    const secret = credData?.client_secret;
    if (secret) {
      copyToClipboard(secret, "client secret");
    } else {
      console.error("No client secret found");
    }
  }

  function copyToClipboard(text, field) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 1500);
    });
  }

  return !data ? null : (
    <div className="relative flex flex-1 z-10">
      {drawerLoadingMessage && (
        <div className="absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div>
            <LoadingMask size={100} loadMessage={drawerLoadingMessage} />
          </div>
        </div>
      )}
      <div
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          paddingBottom: "25px",
          transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
        }}
      >
        <h1 className="text-2xl font-bold mb-4 mt-4 ml-1">Environments</h1>

        <div className="mt-4 ml-1">
          {!orgData && (
            <BasicSelect
              label="Select an Organization:"
              name="product"
              inline={true}
              fullWidth={false}
              placeholder="Loading Organizations..."
              description="Select an organization view environments"
            />
          )}
          {orgData && (
            <BasicSelect
              label="Organization Filter:"
              name="product"
              inline={true}
              fullWidth={false}
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              options={orgData
                ?.filter((org) => !org.archive)
                ?.map((org) => ({
                  id: org.org_id,
                  title: org.name,
                }))}
              placeholder="Select an Organization"
              description="Select an organization to view environments"
            />
          )}
        </div>
        {selectedOrg && (
          <>
            <DataTable
              hideToolbar={true}
              data={data}
              columns={columns}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              setRowSelectionModel={setRowSelectionModel}
              rowSelectionModel={rowSelectionModel}
            ></DataTable>
          </>
        )}
      </div>
      <RightDrawer setWidthFn={setDrawerWidth} width={drawerWidth} isResizing={isResizing}>
        <div style={{ margin: "10px" }}>
          {selectedRow && credData && !credMissingMessage && (
            <>
              <div className="mt-3 mb-4">Armada Client Credentials</div>

              {/* Client ID Section */}
              <div className="bg-zinc-800 rounded-lg">
                <div className="px-3 py-2 border-zinc-700">
                  <p className="text-xs text-zinc-400 mb-1">Client ID</p>
                  <button
                    onClick={() => copyToClipboard(credData.client_id, "client id")}
                    className="w-full flex items-center justify-between bg-zinc-900 rounded p-1.5 hover:bg-zinc-700 transition-colors"
                  >
                    <code className="text-xs text-violet-300 font-mono flex-1 text-left ">{credData.client_id}</code>
                    <CopyIcon className="w-3 h-3 text-violet-400 flex-shrink-0 ml-2" />
                  </button>
                </div>
              </div>

              <div className="border-b border-zinc-700 mt-4 mb-2" />

              {/* API Token Section */}
              <div className="px-3 py-2">
                <button
                  onClick={() => copySecret(credData)}
                  className="w-full flex items-center justify-center space-x-2 py-1.5 text-xs font-medium text-white bg-violet-600 rounded-md hover:bg-violet-500 transition-colors duration-200 ease-in-out"
                >
                  <CopyIcon className="w-3 h-3" />
                  <span>Copy Client Secret</span>
                </button>
              </div>
            </>
          )}

          {credMissingMessage && (
            <>
                <p className="text-zinc-400 mb-3">Credentials can not be retrieved</p>
                  <div className="bg-zinc-800 rounded-lg px-3 py-2 border-zinc-700">
                    <div className="text-xs text-violet-300 font-mono flex-1 text-left ">{credMissingMessage}</div>
                  </div>
            </>
          )}

        </div>
      </RightDrawer>
      {copiedField && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="absolute right-0 mt-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50 whitespace-nowrap"
        >
          <p className="text-xs font-medium">{copiedField === "token" ? "Token" : copiedField} copied to clipboard</p>
        </motion.div>
      )}
    </div>
  );
      }
