import { useEffect, useState } from "react";
import { fetchDirectly } from "../helpers";
import ArmadaTable from "../../armada/ArmadaTable";
import Snackbar from "@mui/material/Snackbar";
import { useAuth } from "contexts/AuthContext";
import { columns, NewEntry } from "./colDefs";

export default function ArmadaTerraformVMOS() {
  const ROW_KEY = "virtual_machine_operating_system_id";
  const ENDPOINT = "terraform/virtual_machine_operating_system";
  const { getAccessTokenSilently } = useAuth();

  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const [data, setData] = useState();

  async function readData() {
    let response = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "GET",
      token: await getAccessTokenSilently(),
    });
    setData(response);
  }

  async function createHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "POST",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    await readData();
    return item;
  }

  async function updateHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "PATCH",
      token: await getAccessTokenSilently(),
      body: editedItem,
      dataId: editedItem[ROW_KEY],
    });
    await readData();
    return item;
  }

 async function deleteHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem[ROW_KEY],
    });
    await readData();
    return item;
  }

  function createVendorColVisibilityModel() {
    var keepers = ["os_version", "os_distribution"];
    return columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((dict, el) => ((dict[el] = false), dict), {});
  }

  function customFilterFunc(field) {
    return ![].includes(field.field);
  }

  useEffect(() => {
    readData();
  }, []);

  return !data ? null : (
    <>
      <ArmadaTable
        title="Virtual Machine Operating System"
        friendlyNameKey="os_distribution"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data && data}
        textFieldFilter={customFilterFunc}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        newModel={NewEntry}
        updateHandler={updateHandler}
        createHandler={createHandler}
        deleteHandler={deleteHandler}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          columns: {
            columnVisibilityModel: createVendorColVisibilityModel(),
          },
          sorting: {
            sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
          },
        }}
      />
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
