export default {
  pageKey: "overrides",
  fields: [
    { field: "target_id", required: true },
    { field: "target_type", required: true },
    { field: "field_name", required: true },
  ],
  tabs: {
    Loot: {
      rowId: "loot_id",
      pluralRowId: "loot_ids",
      targetType: "loot",
      schema: "LootCollection",
      default: true,
      editor: {
        Info: {
          schemas: {
            MUTATE: "Loot",
            GET: "Loot",
            POST: "Loot",
            DELETE: "Loot",
          },
          fields: [
            {
              field: "name",
              headerName: "Name",
              type: "metadata",
            },
            {
              field: "loot_id",
              headerName: "Loot ID",
              type: "metadata",
              tableVisibility: false,
            },
            {
              field: "legacy_loot_id",
              headerName: "Legacy Loot ID",
              type: "metadata",
            },
            {
              field: "item_name",
              headerName: "Item Name",
              type: "metadata",
            },
            {
              field: "active",
              headerName: "Active",
              type: "boolean",
              tableVisibility: false,
              editable: "checkToEnable",
            },
            {
              field: "current_price_point_id",
              headerName: "Current Price Point",
              type: "singleDynamicSelect",
              tableVisibility: false,
              editable: "checkToEnable",
            },
            {
              field: "pre_sale_price_point_id",
              headerName: "Presale Price Point",
              type: "singleDynamicSelect",
              tableVisibility: false,
              editable: "checkToEnable",
            },
          ],
        },
      },
    },
    KV: {
      rowId: "key",
      pluralRowId: "keys",
      targetType: "kv",
      schema: "AllKvs",
      searchKey: "key",
      editor: {
        Info: {
          schemas: {
            MUTATE: "Kv",
            GET: "Kv",
            POST: "Kv",
            DELETE: "Kv",
          },
          fields: [
            {
              field: "key",
              headerName: "Key",
              type: "metadata",
            },
            {
              field: "value",
              headerName: "Value",
              editable: "checkToEnable",
            },
            {
              field: "notes",
              headerName: "Notes",
              tableVisibility: false,
              editable: "checkToEnable",
            },
          ],
        },
      },
    },
  },
};
