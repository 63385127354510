import { useContext, useState, useEffect, useRef } from "react";
import { useEnvironmentStyles } from "../environment/styles";
import { fetchDirectly } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "../../contexts/AccountContext";
import RHDataOnlyField from "../../components/common/RHDataOnlyField";
import RHEnhancedAutocompleteField from "../../components/common/RHEnhancedAutocompleteField";
import RHConfirmDialog from "../../components/common/RHConfirmDialog";
import { Alert, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { getErrorMessage } from "../environment/validation";

export default function SandboxCopy(props) {
  const { inputSandbox, onDialogClose } = props;
  const classes = useEnvironmentStyles();
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData, permissionData, hasPermission } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopying, setIsCopying] = useState(false);
  const [editTargetSandboxDenied, setEditTargetSandboxDenied] = useState(false);
  const [sandbox, setSandbox] = useState({});
  const [targetSandbox, setTargetSandbox] = useState({});
  const initialSandbox = useRef();
  const sandboxOptions = useRef();
  const [resultMsg, setResultMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  const columns = [
    { headerName: "Name", field: "name", required: true },
    { headerName: "Short Name", field: "short_name", required: true },
    {
      headerName: "Primary Contact Email",
      field: "primary_contact_email",
      required: true,
      style: { paddingBottom: "0", marginBottom: "20px" },
    },
  ];

  async function fetchData(inputSandbox) {
    if (!inputSandbox) {
      inputSandbox = currentStateData?.sandbox;
    }
    const sandbox_id = inputSandbox?.sandboxId || inputSandbox?.sandbox_id;

    if (!sandboxOptions.current) {
      let sandboxes = currentStateData?.product?.sandboxes
        .filter((x) => !x.archive)
        .filter((x) => !x.readonly)
        .filter((x) => x.sandboxId !== sandbox_id)
        .sort(function (a, b) {
          let nameA = a.sandboxName.toLowerCase();
          let nameB = b.sandboxName.toLowerCase();
          return nameA < nameB ? -1 : nameB > nameA ? 1 : 0;
        })
        .map((x) => ({ id: x.sandboxId, title: x.sandboxName }));
      if (sandboxes.length < 1) {
        sandboxes.push({ id: null, title: "" });
      }
      sandboxOptions.current = sandboxes;
    }

    setErrorMsg();
    if (sandbox_id) {
      try {
        const endpoint = `v1/org/${currentStateData?.org?.orgId}/product/${currentStateData?.product?.productId}/sandbox/${
          currentStateData?.sandbox?.sandboxId ?? sandbox_id
        }`;
        const response = await fetchDirectly({
          token: await getAccessTokenSilently(),
          fullUrl: endpoint,
          method: "GET",
        });
        initialSandbox.current = response;
        setSandbox(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setResultMsg(getErrorMessage(error, columns));
        setIsLoading(false);
      }
    }
  }

  function isPermitted(sandbox_id) {
    return true;
  }

  const handleCopy = () => {
    if (!dialogProps.open) {
      const msg =
        `Are you sure you want to copy "${initialSandbox.current?.name}" into "${targetSandbox.sandboxName}"?\n\n` +
        `This action will delete all existing content in "${targetSandbox.sandboxName}".\n\n` +
        `This action cannot be undone.`;
      setDialogProps({
        open: true,
        title: "Confirm Sandbox Copy",
        content: msg,
        onConfirm: () => actuallyCopy(),
        onCancel: () => handleConfirmDialogCancel(),
      });
    }
  };

  const actuallyCopy = async () => {
    handleConfirmDialogCancel();
    let bodyelem = document.querySelector("body");
    bodyelem.style.pointerEvents = "none";

    setIsCopying(true);
    setErrorMsg();
    try {
      let endpoint = `v1/sandbox/${sandbox.sandbox_id}/copy`;
      await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: endpoint,
        method: "POST",
        body: { target_sandbox_id: targetSandbox.sandboxId },
      });
      bodyelem.style.pointerEvents = "initial";
      setIsCopying(false);
      setResultMsg(`"${sandbox.name}" sandbox successfully copied to "${targetSandbox.sandboxName}"`);
    } catch (error) {
      bodyelem.style.pointerEvents = "initial";
      setIsCopying(false);
      handleError(error);
    }
  };

  const handleConfirmDialogCancel = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  const handleChange = (event) => {
    let { value } = event.target;
    setTargetSandbox(currentStateData?.product?.sandboxes.find((x) => x.sandboxId == value));
    setEditTargetSandboxDenied(!isPermitted(value));
  };

  const handleResultClose = async () => {
    if (onDialogClose) {
      onDialogClose();
    }
  };

  const handleError = (error) => {
    const errorMessage = getErrorMessage(error, columns);
    console.log(error);
    setErrorMsg(errorMessage);
  };

  useEffect(() => {
    if (!currentStateData) return;
    fetchData(inputSandbox);
  }, [currentStateData]);

  return isLoading ? null : (
    <>
      <Box key="sandbox_form">
        {resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ padding: "20px", marginTop: "50px" }}>
              <Typography variant="h5" color="textPrimary">
                {resultMsg}
              </Typography>
            </Box>
            <Box className={classes.gameConfigToolbar} sx={{ padding: "20px", marginTop: "10px" }}>
              <Button variant="contained" color="primary" onClick={() => handleResultClose()}>
                Close
              </Button>
            </Box>
          </>
        )}
        {!resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ paddingBottom: "15px" }}>
              <Typography variant="body2"></Typography>
              <Box display="flex" gap={2}>
                <>
                  {isPermitted() && (
                    <>
                      <Button
                        onClick={() => handleCopy()}
                        variant="contained"
                        color="primary"
                        disabled={!targetSandbox?.sandboxId || isCopying || editTargetSandboxDenied}
                      >
                        Execute
                        {isCopying ? <CircularProgress size={24} style={{ marginLeft: "10px" }} /> : null}
                      </Button>
                    </>
                  )}
                </>
              </Box>
            </Box>
            {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : <></>}
            <Box className={classes.rootGameConfigPage}>
              <Box>
                <Box>
                  <Box className={classes.gameConfigFormControls}>
                    <Typography>Copy From:</Typography>
                    <Typography fontSize="20px" sx={{ marginLeft: "5px", marginTop: "-10px" }}>
                      {sandbox?.name}
                    </Typography>
                    <div style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}>
                      <RHDataOnlyField label="Sandbox ID" name="sandbox_id" value={sandbox?.sandbox_id || ""} />
                    </div>
                    <Typography>Copy To:</Typography>
                    <div style={{ marginTop: "0px", marginLeft: "0px", width: "100%" }}>
                      <RHEnhancedAutocompleteField
                        key="sandbox_id"
                        name="sandbox_id"
                        label=""
                        description="Target Sandbox"
                        required={true}
                        isDynamic={false}
                        advancedSearch={false}
                        idKey="sandbox_id"
                        displayValue="name"
                        defaultOptions={sandboxOptions.current}
                        filterOnClient={true}
                        onChange={handleChange}
                        sx={{ marginTop: "-5px", width: "100%", marginBottom: "0px", marginLeft: "0px", width: "100%" }}
                      />
                      {editTargetSandboxDenied && (
                        <Alert severity="error" sx={{ marginTop: "-10px" }}>
                          You are not authorized to edit target Sandbox
                        </Alert>
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <RHConfirmDialog
        open={dialogProps.open}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
        title={dialogProps.title}
        content={dialogProps.content}
      />
    </>
  );
}
