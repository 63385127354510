import React from "react";
import { flexRender } from "@tanstack/react-table";
import { ArrowUpIcon, ArrowDownIcon, DropDownMenuIcon } from "assets/svgs";

const DataTableHeader = ({ table, setSorting, columnsWithSort, enableMultiRowSelection }) => {
  return (
    <thead className="bg-zinc-800 border border-white/10 sticky -top-[1px]">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {enableMultiRowSelection && <th className="p-1.5 font-thin  top-0 border border-white/10"></th>}
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              className="p-1.5 font-thin whitespace-nowrap overflow-hidden text-ellipsis top-0 border border-white/10"
              onClick={columnsWithSort.includes(header.id) ? header.column.getToggleSortingHandler() : null}
              title={
                header.column.getCanSort()
                  ? header.column.getNextSortingOrder() === "asc"
                    ? "Sort ascending"
                    : header.column.getNextSortingOrder() === "desc"
                      ? "Sort descending"
                      : "Clear sort"
                  : undefined
              }
              style={{
                width: header.getSize(),
              }}
            >
              <div className={`cursor-pointer select-none flex justify-between items-center `}>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() &&
                  (header.column.getIsSorted() === "asc" ? <ArrowUpIcon width="16" /> : <ArrowDownIcon width="16" />)}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default React.memo(DataTableHeader);
