import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useSandboxStyles } from "../sandbox/styles";
import AccountContext from "../../contexts/AccountContext";
import RHLoadingMask from "../../components/common/RHLoadingMask";
import RHEditableTable from "../../components/common/RHEditableTable";
import { useAuth } from "contexts/AuthContext";
import { fetchDirectly } from "common/apiUtils";
import RHConfirmDialog from "../../components/common/RHConfirmDialog";
import RHProductClientSecretDialog from "./RHProductClientSecretDialog";

export default function RHProductClientPage() {
  const classes = useSandboxStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });
  const policyLookup = useRef();
  const envLookup = useRef();
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dialogType, setDialogType] = useState(false);
  const sdata = useRef();
  const rawSecretAvailable = useRef(false)
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
    type: "rotate"
  });

  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();

  const API_NAME = "clients";
  const ROW_KEY = "client_id";

  const COLUMNS = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      required: true,
    },
    {
      field: "client_id",
      headerName: "ID",
      flex: 1,
      alwaysDisabled: true,
      hideable: false,
      type: "metadata",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      required: true,
    },
    {
      field: "policy_name",
      headerName: "Policy",
      flex: 1,
    },
    {
      field: "policy_id",
      headerName: "Policy",
      flex: 1,
      displayValue: "name",
      advancedSearch: false,
      isDynamic: false,
      type: "enhancedautocomplete",
      filterOnClient: true,
      required: true,
      hideable: true,
    },
    {
      field: "environment_name",
      headerName: "Environment",
      flex: 1,
    },
    {
      field: "environment_id",
      headerName: "Environment",
      flex: 1,
      displayValue: "name",
      advancedSearch: false,
      isDynamic: false,
      type: "enhancedautocomplete",
      filterOnClient: true,
      required: false,
      hideable: true,
    },
  ];

  class clientEntry {
    constructor() {}
  }

  async function refreshData() {
    return await fetchData();
  }

  function mapClientFromApi(c) {
    c.policy_id = c.policy.policy_id;
    if (policyLookup.current) {
      c.policy_name = policyLookup.current[c.policy.policy_id];
    }
    delete c.policy;

    if (envLookup.current) {
      c.environment_name = envLookup.current[c.environment_id];
    }
    return c;
  }

  async function fetchData() {
    try {
      const client_fetch = new Promise(async (resolve) => {
        const productId = currentStateData?.product?.productId;
        const resp = await fetchDirectly({
          fullUrl: `v1/environment-client?product_id=${productId}`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        resolve(resp);
      });

      const policy_fetch = new Promise(async (resolve) => {
        const productId = currentStateData?.product?.productId;
        const resp = await fetchDirectly({
          fullUrl: `v1/environment-policy?product_id=${productId}`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        resolve(resp);
      });

      const env_fetch = new Promise(async (resolve) => {
        const productId = currentStateData?.product?.productId;
        const resp = await fetchDirectly({
          fullUrl: `v1/product/${productId}/environment`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        resolve(resp);
      });

      const resp = await Promise.all([client_fetch, policy_fetch, env_fetch]).then((responses) => {
        let policies = responses[1].policies;
        policyLookup.current = policies.reduce((acc, val) => {
          acc[val.policy_id] = val.name;
          return acc;
        }, {});

        let envs = responses[2];
        envLookup.current = envs.reduce((acc, val) => {
          acc[val.environment_id] = val.name;
          return acc;
        }, {});

        const clients = responses[0].clients.map((x) => mapClientFromApi(x));

        const policyOptions = responses[1].policies
          .filter((x) => x.name)
          .map((x) => ({
            title: x.name,
            id: x.policy_id,
          }));
        const policyCol = COLUMNS.find((x) => x.field === "policy_id");
        policyCol.defaultOptions = policyOptions;

        const envOptions = responses[2]
          .filter((x) => x.name)
          .map((x) => ({
            title: x.name,
            id: x.environment_id,
          }));
        const envCol = COLUMNS.find((x) => x.field === "environment_id");
        envCol.defaultOptions = envOptions;

        setColumns(COLUMNS);

        responses[0].clients = clients;
        return responses[0];
      });

      setData(resp.clients);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (currentStateData?.product?.productId) {
      fetchData();
    }
  }, [currentStateData]);

  function customFilterFunc(field) {
    return !["created_timestamp", "policy_name", "environment_name"].includes(field.field);
  }

  async function createHandler(apiName, onDataRefresh, sandboxId, editedItem) {
    setDialogProps((prev) => ({ ...prev, type: 'rotate' }));
    const productId = currentStateData?.product?.productId;
    if (!editedItem.environment_id){
      editedItem.product_id = productId;
    }
    const item = await fetchDirectly({
      fullUrl: `v1/environment-client?product_id=${productId}`,
      method: "POST",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    setSelectedRow(mapClientFromApi(item));
    sdata.current = item.client_secret;
    handleDialogClickOpen();
    fetchData();
    return item;
  }

  async function updateHandler(apiName, onDataRefresh, editedItemId, sandboxId, editedItem) {
    const productId = currentStateData?.product?.productId;
    if (!editedItem.environment_id){
      editedItem.product_id = productId;
    }
    const item = await fetchDirectly({
      fullUrl: `v1/environment-client/${editedItemId}`,
      method: "PUT",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    fetchData();
    return item;
  }

  async function deleteHandler(apiName, onDataRefresh, editedItemId) {
    const item = await fetchDirectly({
      fullUrl: `v1/environment-client/${editedItemId}`,
      method: "DELETE",
      token: await getAccessTokenSilently(),
    });
    fetchData();
    return item;
  }

  if (isLoading) {
    return <RHLoadingMask />;
  }

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
    columns: {
      columnVisibilityModel: {
        policy_id: false,
        environment_id: false,
      },
    },
    sorting: {
      sortModel: [{ field: "name", sort: "asc" }],
    },
  };

  function handleDialogClickOpen() {
    setOpen(true);
  }

  function handleDialogClose(value) {
    setOpen(false);
  }

  function handleConfirmDialogClose(value) {
    setDialogProps((prev) => ({ ...prev, open: false }));
  }

  async function handleConfirmRotation(value) {
    setDialogProps((prev) => ({ ...prev, type: 'rotate' }));
    handleConfirmDialogClose();
    try {
      const client = { ...selectedRow };
      client.policy_id = selectedRow.policy_id;
      client.rotate_secret = true;

      const item = await fetchDirectly({
        fullUrl: `v1/environment-client/${client.client_id}`,
        method: "PUT",
        token: await getAccessTokenSilently(),
        body: client,
      });

      sdata.current = item.client_secret;
    } catch (err) {
      sdata.current = null;
    }
    rawSecretAvailable.current = true
    handleDialogClickOpen();
  }

  async function handleRotateKey() {
    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Client Secret Rotation",
        content: `Are you sure you want to rotate the secret? This action cannot be undone. Use extreme caution! \n
                  Any game clients using the old secret will no longer be able to connect.`,
        onConfirm: () => handleConfirmRotation(),
        onCancel: () => handleConfirmDialogClose(),
      });
    }
  }

  async function handleConfirmViewSecret(value) {
    setDialogProps((prev) => ({ ...prev, type: 'display' }));
    handleConfirmDialogClose();
    try {
      const client = { ...selectedRow };

      const item = await fetchDirectly({
        fullUrl: `v1/environment-client/${client.client_id}`,
        method: "GET",
        token: await getAccessTokenSilently(),
      });
      let secret = item?.client_secret_raw;
      if (!secret || secret.length === 0) {
        secret = "Secret not available for retrieval"
      }
      sdata.current = secret;
    } catch (err) {
      sdata.current = null;
    }
    handleDialogClickOpen();
  }

  async function handleViewSecret() {
    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Display Secret",
        content: `Are you sure you want to display the raw secret? This is sensitive data. Use caution.`,
        onConfirm: () => handleConfirmViewSecret(),
        onCancel: () => handleConfirmDialogClose(),
      });
    }
  }

  function handleDialogClickOpen() {
    setOpen(true);
  }

  function handleRetrieveSelectedRow(item, isEdit) {
    const og_client = data.find((x) => x.client_id === item?.client_id)
    rawSecretAvailable.current = og_client?.client_secret_raw !== undefined;
    setSelectedRow(item);
  }

  const renderRotateKeyBtn = () => (
    <Tooltip title="Generate new client secret" key="additional_rotate_button">
      <Button onClick={handleRotateKey} key="rotate_secret" variant="contained">
        Rotate Secret
      </Button>
    </Tooltip>
  );

  const renderShowSecretBtn = () => {
    const disabled = !rawSecretAvailable.current
    const tooltipText = disabled ? "Raw secret is not available for retrieval" : "Display existing raw secret"
    return <Tooltip title={tooltipText} key="additional_show_button">
      <span>
        <Button onClick={handleViewSecret} key="show_secret" variant="contained" disabled={disabled}>
          Show Secret
        </Button>
     </span>
    </Tooltip>
  };

  let additionalEditButtons = [renderRotateKeyBtn]
  const canViewSecret = currentStateData?.product?.permissions?.includes("globalAdmin:*:*")
    || currentStateData?.product?.permissions?.includes("clients:secret:view")
  if (canViewSecret)
  {
    additionalEditButtons.push(renderShowSecretBtn)
  }


  const sandbox = { sandboxId: currentStateData?.product?.productId };
  const product = { productId: currentStateData?.product?.productId };
  return (
    <>
      <Box className={classes.rootTableContainer}>
        <RHEditableTable
          additionalEditButtons={additionalEditButtons}
          apiName={API_NAME}
          title="Client Editor"
          friendlyName="Client"
          friendlyNameKey="name"
          rowKey={ROW_KEY}
          columns={columns}
          tableData={data}
          initialState={initialState}
          sandbox={sandbox}
          product={product}
          onDataRefresh={refreshData}
          createHandler={createHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}
          hasEditPermission={
            currentStateData?.product?.permissions?.includes("globalAdmin:*:*") ||
            currentStateData?.product?.permissions?.includes("clients:config:edit")
          }
          textFieldFilter={customFilterFunc}
          className={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          retrieveSelectedRows={handleRetrieveSelectedRow}
          retrieveNewItemAsSelectedRow
          editableInline={false}
          newModel={clientEntry}
          newModelArgs={[]}
          isRowEditable={(row) => !row.default_policy}
        />
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
        <RHProductClientSecretDialog data={sdata.current} open={open} type={dialogProps.type} onClose={handleDialogClose} />
      </Box>
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
