import { useState } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { apiRequest } from "common/apiUtils";
import { CloseIcon } from "assets/svgs";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/common/datatable";
import dayjs from "dayjs";

export default function ImportSchedule({ isOpen, onClose, productId, environmentId, onSuccess, onError }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [step, setStep] = useState(1); // Track wizard steps
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    schedule_id: false,
    name: true,
    description: true,
    start_datetime: true,
  });

  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper();

  const schedulesList = useQuery({
    queryKey: ["schedules", productId],
    queryFn: async () => {
      const response = await apiRequest(
        await getAccessToken(),
        {
          path: "/v1/product/{product_id}/environment/{environment_id}/copyable-schedules",
          method: "GET",
        },
        {
          product_id: productId,
          environment_id: environmentId,
        },
        null,
        {},
        {
          queryParams: "sort_order=asc&cursor=0&page_size=50",
        },
      );
      return response.data || [];
    },
    enabled: isOpen && !!productId,
  });

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
      minSize: 250,
      maxSize: 500,
    }),
    columnHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => <span>Description</span>,
      size: Number.MAX_SAFE_INTEGER,
    }),
    columnHelper.accessor("start_datetime", {
      cell: (info) => dayjs(info.getValue()).format("llll"),
      header: () => <span>Start Time</span>,
      size: Number.MAX_SAFE_INTEGER,
    }),
  ];

  const handleImport = async (sync) => {
    if (!selectedRow) return;

    try {
      const scheduleId = selectedRow?.original?.schedule_id || selectedRow?.schedule_id;

      const response = await apiRequest(
        await getAccessToken(),
        {
          path: "/v1/product/{product_id}/environment/{environment_id}/schedule/{schedule_id}/copy",
          method: "POST",
        },
        {
          product_id: productId,
          environment_id: environmentId,
          schedule_id: scheduleId,
        },
        null, // body is null for this request
        {}, // no extra headers
        {
          queryParams: `sync=${sync}`, // This is the correct way to send a query parameter
        },
      );

      if (response) {
        queryClient.invalidateQueries({ queryKey: ["schedules"] });
        onSuccess(
          sync
            ? "Schedule synced successfully! Changes to the source will be reflected here."
            : "Schedule copied successfully! This is now an independent schedule.",
        );
        onClose();
      }
    } catch (err) {
      onError(err.message || "Failed to import schedule");
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/80 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <motion.div
          className="bg-zinc-900 border border-white/10 rounded-sm w-[900px] max-h-[60vh] p-6 flex flex-col"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-xl font-bold text-white">Import Schedule</h2>
              <div className="flex items-center gap-3 mt-3">
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2.5 h-2.5 rounded-full ${step === 1 ? "bg-violet-500" : "bg-white/20"}
                    transition-colors duration-200`}
                  ></div>
                  <span className={`text-sm ${step === 1 ? "text-white" : "text-white/50"}`}>Select Schedule</span>
                </div>
                <div className="w-8 h-[1px] bg-white/10"></div>
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2.5 h-2.5 rounded-full ${step === 2 ? "bg-violet-500" : "bg-white/20"}
                    transition-colors duration-200`}
                  ></div>
                  <span className={`text-sm ${step === 2 ? "text-white" : "text-white/50"}`}>Choose Import Type</span>
                </div>
              </div>
            </div>
            <button onClick={onClose} className="text-white/70 hover:text-white">
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>

          {step === 1 ? (
            <div className="flex-1 overflow-hidden">
              {schedulesList.isPending ? (
                <div className="flex items-center justify-center h-full">
                  <span className="text-white">Loading schedules...</span>
                </div>
              ) : (
                <div className="h-[calc(45vh-120px)] overflow-auto">
                  <DataTable
                    data={schedulesList.data}
                    columns={columns}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnsWithSort={["name", "description", "start_datetime"]}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    setRowSelectionModel={setRowSelectionModel}
                    rowSelectionModel={rowSelectionModel}
                    defaultSortKey="start_datetime"
                    rowId="schedule_id"
                    className="h-full"
                    readPermitted={true}
                    editPermitted={false}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-1 overflow-y-scroll">
              <div className="text-white mb-6">
                <div className="bg-zinc-800/50 p-4 rounded-md border border-white/5">
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <p className="text-white/50 text-sm mb-1">Name</p>
                      <p className="text-white font-medium">{selectedRow?.original?.name || selectedRow?.name}</p>
                    </div>
                    <div>
                      <p className="text-white/50 text-sm mb-1">Start Time</p>
                      <p className="text-white font-medium">
                        {dayjs(selectedRow?.original?.start_datetime || selectedRow?.start_datetime).format("llll")}
                      </p>
                    </div>
                    <div className="col-span-2">
                      <p className="text-white/50 text-sm mb-1">Description</p>
                      <p className="text-white font-medium">
                        {selectedRow?.original?.description || selectedRow?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <div
                  className="bg-zinc-800/80 p-5 rounded-md border border-white/5 hover:bg-zinc-700/80 cursor-pointer transition-all duration-200 hover:border-emerald-500/30"
                  onClick={() => handleImport(true)}
                >
                  <div className="flex items-center mb-1.5">
                    <div className="w-2 h-2 rounded-full bg-emerald-500 mr-2"></div>
                    <h4 className="text-white font-semibold">Sync Copy</h4>
                  </div>
                  <p className="text-white/70 text-sm leading-relaxed pl-4">
                    Create a synchronized copy that will stay updated with the source schedule.
                  </p>
                </div>

                <div
                  className="bg-zinc-800/80 p-5 rounded-md border border-white/5 hover:bg-zinc-700/80 cursor-pointer transition-all duration-200 hover:border-sky-500/30"
                  onClick={() => handleImport(false)}
                >
                  <div className="flex items-center mb-1.5">
                    <div className="w-2 h-2 rounded-full bg-sky-500 mr-2"></div>
                    <h4 className="text-white font-semibold">Full Copy</h4>
                  </div>
                  <p className="text-white/70 text-sm leading-relaxed pl-4">
                    Create an independent copy with a new schedule ID.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 mt-4">
            {step === 2 && (
              <button
                onClick={() => setStep(1)}
                className="px-4 py-2 rounded text-white/70 hover:text-white border border-white/10 hover:border-white/30 transition-colors"
              >
                Back
              </button>
            )}
            <button
              onClick={onClose}
              className="px-4 py-2 rounded text-white/70 hover:text-white border border-white/10 hover:border-white/30 transition-colors"
            >
              Cancel
            </button>
            {step === 1 && (
              <button
                onClick={() => setStep(2)}
                disabled={!selectedRow}
                className={`px-4 py-2 rounded bg-violet-600 text-white font-medium transition-colors
                  ${!selectedRow ? "opacity-50 cursor-not-allowed" : "hover:bg-violet-500"}`}
              >
                Next
              </button>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  );
}
