import { Link } from "react-router-dom";
import MachineClassType from "views/armada/MachineClassType";
import { summarizeMachineClass } from "../ArmadaMachineClasses/colDefs";

export function createColumnDefs() {
  return [
    {
      field: "public_hostname",
      headerName: "Public Hostname",
      minWidth: 200,
      required: false,
      editable: true,
      flex: 1,
    },
    {
      field: "simple_product_assignments",
      headerName: "Simple Product Assignments",
      minWidth: 200,
      nullify: true,
      valueGetter: (params) => {
        return params?.row?.simple_product_assignments
          ? params?.row?.simple_product_assignments?.map((x) => x.name).join(", ")
          : "";
      },
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      minWidth: 200,
      flex: 1,
      type: "string",
      nullify: true,
      renderCell: (params) => (
        <MachineClassType value={params?.row?.machine_class_type} virtualMachine={params.value} />
      ),
    },
    {
      field: "virtual_machine",
      headerName: "Virtual Machine",
      editable: true,
      minWidth: 200,
      flex: 1,
      type: "boolean",
    },
    {
      field: "public_ipv4",
      headerName: "Public IPv4",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "public_ipv6",
      headerName: "Public IPv6",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "internal_hostname",
      headerName: "Vendor Server Name",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "internal_ipv4",
      headerName: "Internal IPv4",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "internal_ipv6",
      headerName: "Internal IPv6",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "monthly_price",
      headerName: "Monthly Price",
      minWidth: 200,
      editable: true,
      flex: 1,
      type: "number",
    },
    {
      field: "hourly_price",
      headerName: "Hourly Price",
      minWidth: 200,
      editable: true,
      flex: 1,
      type: "number",
    },
    {
      field: "notes",
      headerName: "Notes",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "geographic_location_id",
      headerName: "Geographic Location",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.geographic_location_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/geographic_location",
      filterOnClient: true,
      idKey: "geographic_location_id",
      displayValue: "description",
    },
    {
      field: "datacenter_location_id",
      headerName: "Datacenter Location",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.datacenter_location_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/datacenter_location",
      filterOnClient: true,
      idKey: "datacenter_location_id",
      displayValue: "description",
    },
    {
      field: "datacenter_provider_id",
      headerName: "Datacenter Provider",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.datacenter_provider_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/datacenter_provider",
      filterOnClient: true,
      idKey: "datacenter_provider_id",
      displayValue: "name",
    },

    {
      field: "machine_class_id",
      headerName: "Machine Class",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/admin/armada/machine-class/${params.row.machine_class_id}`} tabIndex={params.tabIndex}>
          {params.row.machine_class_friendly}
        </Link>
      ),
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/machine_class",
      urlResponseFilter: (response) => response.map((mc) => ({ ...mc, name: summarizeMachineClass(mc) })),
      filterOnClient: true,
      idKey: "machine_class_id",
      displayValue: "name",
    },
    {
      field: "machine_class_overrides_id",
      headerName: "Machine Class Overrides",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.machine_class_overrides_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/machine_class",
      filterOnClient: true,
      idKey: "machine_class_id",
      displayValue: "name",
    },
    {
      field: "machine_image_id",
      headerName: "Machine Image",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.machine_image_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/machine_image",
      filterOnClient: true,
      idKey: "machine_image_id",
      displayValue: "name",
    },
    {
      field: "datacenter_contract_id",
      headerName: "Datacenter Contract",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.datacenter_contract_friendly,
      type: "enhancedautocomplete",
      editable: true,
      isDynamic: true,
      fullUrl: "v1/armada/datacenter_contract",
      filterOnClient: true,
      idKey: "datacenter_contract_id",
      displayValue: "purchase_order",
    },
    {
      field: "prior_host_id",
      headerName: "Prior Host ID",
      minWidth: 200,
      type: "metadata",
      flex: 2,
    },
    {
      field: "host_id",
      headerName: "Host ID",
      minWidth: 200,
      type: "metadata",
      flex: 2,
    },

    { field: "datacenter_contract_friendly", nullify: true },
    { field: "datacenter_location_friendly", nullify: true },
    { field: "datacenter_provider_friendly", nullify: true },
    { field: "geographic_location_friendly", nullify: true },
    { field: "machine_class_friendly", nullify: true },
    { field: "machine_class_overrides_friendly", nullify: true },
    { field: "machine_image_friendly", nullify: true },
    { field: "geographic_location", nullify: true },
    { field: "datacenter_provider", nullify: true },
    { field: "datacenter_location", nullify: true },
    { field: "datacenter_contract", nullify: true },
    { field: "machine_class", nullify: true },
    { field: "machine_class_overrides", nullify: true },
    { field: "machine_image", nullify: true },
    {
      field: "labels",
      nullify: true,
      valueGetter: (params) => params.row.labels.join(","),
    },
    { field: "machine_class_type", nullify: true },
  ];
}

export class NewEntry {
  description = "";
}
